import React, { useContext } from 'react';
import Modal from 'react-modal';
import { customModalStyles } from '../../config/config';
import { useCrypto } from '../../hooks/crypto.hook';
import { AuthContext } from '../../context/AuthContext';
import './DeleteTenantModal.css';

export const DeleteTenantModal = ({
  isOpen,
  onRequestClose,
  editTenant,
  callback,
  requestWithSecondaryLoading,
  secondaryLoading
}) => {
  const { encryptData } = useCrypto();
  const { token, showToastMessage } = useContext(AuthContext);

  const updateTenant = async (tenantId, tenantName, type) => {
    try {
      onRequestClose();
      const data = encryptData({tenantId, tenantName, type});
      const res = await requestWithSecondaryLoading('/back_office/api/user/delete_tenant', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })

      showToastMessage(res.error, res.message);

      if (callback) callback();
    } catch (error) { }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Delete Modal"
      style={customModalStyles}
    >
      <div className='delete-tenant-modal'>
        <div className='delete-tenant-content'>
          <span className="card-title">Delete tenant</span>
          <span className='card-subtitle'>
            Are you sure you want to delete this tenant? <strong>(This action cannot be undone)</strong>
          </span>
          <div className="delete-modal-buttons">
            <button onClick={onRequestClose} className="modal-button-cancel">Cancel</button>
            <button
              onClick={() => updateTenant(editTenant.tenantId, editTenant.tenantName, editTenant.type)}
              className="modal-button-delete"
              disabled={secondaryLoading}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
