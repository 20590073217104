import React, { useContext, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import moment from 'moment';
import ReactSelect from '../../ReactSelect/ReactSelect';
import { DateRangeOption, DepartmentOption, LocationOption, TenantOption } from '../../ReactSelect/ReactSelectOption';
import { AnalyticsGeneralContext } from '../../../context/AnalyticsGeneralContext';
import { dateRangeOptions } from '../../../config/config';
import './UserAnalyticsFilterBar.css';

export const UserAnalyticsFilterBar = ({
  loading, tenants, departments, locations, minMailingDate, setMinMailingDate, selectedTenant,
  setSelectedTenant, selectedDepartment, setSelectedDepartment, dateRangeOptionsList,
  filteredMinMailingDate, selectedLocation, setSelectedLocation, selectedDateRange,
  setSelectedDateRange, setDateRangeOptionsList, maxMailingDate, setMaxMailingDate
}) => {
  const { minMailingDate: contextMinMailingDate } = useContext(AnalyticsGeneralContext);

  const resetDateRange = () => {
    setSelectedDateRange(dateRangeOptions[0]);
    setMinMailingDate(moment().subtract(30, 'days').format('YYYY-MM-DD'));
  }

  const handleDepartmentChange = (data) => {
    setSelectedDepartment(data);
    resetDateRange();
  };

  const handleLocationChange = (data) => {
    setSelectedLocation(data);
    resetDateRange();
  };

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    resetDateRange();
  };

  const handleDateRangeChange = (data) => {
    if (!data || data?.value === 0) {
      setMinMailingDate(contextMinMailingDate);
    } else {
      setMinMailingDate(moment().subtract(data.value, 'days').format('YYYY-MM-DD'));
    }
    setMaxMailingDate(moment().format('YYYY-MM-DD'));
    setSelectedDateRange(data);
  };

  useEffect(() => {
    const minDateDiff = moment().diff(moment(filteredMinMailingDate), 'days');
    setDateRangeOptionsList(dateRangeOptions.filter(option => (option.value <= minDateDiff) || option.value === 0));
  }, [filteredMinMailingDate, setDateRangeOptionsList]);

  return (
    <div className='user-analytics-input-container'>
      <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--tenant', {
        'input-container-analytics-item--disabled': loading
      })}>
        <ReactSelect
          options={tenants}
          optionComponent={TenantOption}
          placeholder={'Tenant'}
          classNames={'option-select-analytics'}
          value={selectedTenant}
          onChange={handleTenantChange}
          isSearchable={true}
          isClearable={true}
          optionHeight={30}
          rows={10}
        />
      </div>
      <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--department', {
        'input-container-analytics-item--disabled': loading
      })}>
        <ReactSelect
          options={departments}
          optionComponent={DepartmentOption}
          placeholder={'Department'}
          classNames={'option-select-analytics'}
          value={selectedDepartment}
          onChange={handleDepartmentChange}
          isSearchable={true}
          isClearable={true}
          optionHeight={32}
          rows={10}
        />
      </div>
      <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--location', {
        'input-container-analytics-item--disabled': loading
      })}>
        <ReactSelect
          options={locations}
          optionComponent={LocationOption}
          placeholder={'Location'}
          classNames={'option-select-analytics'}
          value={selectedLocation}
          onChange={handleLocationChange}
          isSearchable={true}
          isClearable={true}
          optionHeight={32}
          rows={10}
        />
      </div>

      <div className='analytics-date-range-container'>
        <div className='input-field-history-date-range-container' >
          <div className={cn("input-field-history", {
            'input-container-analytics-item--disabled': loading
          })}>
            <DatePicker
              selected={minMailingDate ? moment(minMailingDate).format() : null}
              onChange={(date) => {
                setMinMailingDate(moment(date).format('YYYY-MM-DD'));
                setSelectedDateRange({ value: -1, label: 'Custom range' });
              }}
              selectsStart
              onKeyDown={(e) => e.preventDefault()}
              minDate={filteredMinMailingDate ? moment(filteredMinMailingDate).format() : null}
              maxDate={maxMailingDate ? moment(maxMailingDate).format() : null}
              className={cn("input-edit-history", {"input-edit-history-loading": loading})}
              disabled={loading}
              placeholderText="Enter Date"
            />
          </div>
          <p>&#11020;</p>
          <div className={cn("input-field-history", {
            'input-container-analytics-item--disabled': loading
          })}>
            <DatePicker
              selected={maxMailingDate ? moment(maxMailingDate).format() : null}
              onChange={(date) => {
                setMaxMailingDate(moment(date).format('YYYY-MM-DD'));
                setSelectedDateRange({ value: -1, label: 'Custom range' });
              }}
              selectsStart
              onKeyDown={(e) => e.preventDefault()}
              minDate={filteredMinMailingDate ? moment(filteredMinMailingDate).format() : null}
              maxDate={maxMailingDate ? moment().format() : null}
              className={cn("input-edit-history", {"input-edit-history-loading": loading})}
              disabled={loading}
              placeholderText="Enter Date"
            />
          </div>
        </div>
      </div>
      <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--date-range', {
        'input-container-analytics-item--disabled': loading
      })}>
        <ReactSelect
          options={ dateRangeOptionsList }
          optionComponent={ DateRangeOption }
          placeholder={'Date Range'}
          onChange={handleDateRangeChange}
          value={selectedDateRange}
          isSearchable={false}
          isDisabled={loading}
          optionHeight={28}
          rows={10}
        />
      </div>
    </div>
  )
};
