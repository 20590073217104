import React, { useState, useContext, useMemo } from 'react';
import { useHttp } from '../hooks/http.hook';
import { useCrypto } from '../hooks/crypto.hook';
import { AnalyticsGeneralContext } from './AnalyticsGeneralContext';
import { AuthContext } from './AuthContext';
import { Loader } from '../components/Loader/Loader';

export const AnalyticsGeneralProvider = ({ children }) => {
  const { token } = useContext(AuthContext);
  const { decryptData } = useCrypto();
  const { request, loading } = useHttp();

  const [tenants, setTenants] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [minMailingDate, setMinMailingDate] = useState(null);
  const [generalLoading, setGeneralLoading] = useState(false);

  useMemo(async () => {
    try {
      if (token) {
        const analyticsGeneralData = await request('/back_office/api/analytics/general_data', 'GET', null, {
          Authorization: `Bearer ${token}`
        });
        const decryptAnalyticsGeneralData = decryptData(analyticsGeneralData);

        setTenants(decryptAnalyticsGeneralData?.tenants);
        setDepartments(decryptAnalyticsGeneralData?.departments);
        setLocations(decryptAnalyticsGeneralData?.locations);
        setMinMailingDate(decryptAnalyticsGeneralData?.minMailingDate);

        return analyticsGeneralData;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <AnalyticsGeneralContext.Provider value={{
      tenants,
      departments,
      locations,
      minMailingDate,
      generalLoading,
      setGeneralLoading
    }}>
      {!loading ? children : <Loader />}
    </AnalyticsGeneralContext.Provider>
  );
};
