import React from 'react';
import './ProneTable.css';

export const TemplateProneTable = ({ title, columns, data }) => {
  return (
    <div className="table-container">
      <h3>{title}</h3>
      <table>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th
                key={index}
                style={{width: `${100 / columns?.length}%`}}
              >
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={{width: `${100 / columns?.length}%`}}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
