import React, { useCallback, useEffect, useContext, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';
import cn from 'classnames';
import JsonView from 'react18-json-view';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import { AuthContext } from '../../context/AuthContext';
import { useTitle } from '../../hooks/title.hook';
import { getSubjectFromDescription } from '../../common/getDescription';
import { Pagination } from '../../components/Pagination/Pagination';
import { itemsOnPage } from '../../config/config';
import 'react18-json-view/src/style.css';
import './ReportedPhish.css';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
  content: {
    padding: '0',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 2px 10px rgba(52, 50, 50, 0.2)',
    borderRadius: '4px'
  },
};

export const PhishReports = ({ type }) => {
  let title = '';
  let formattedType = '';
  switch (type) {
    case 'phish':
      title = 'Phish';
      formattedType = 'phish';
      break;
    case 'not-phish':
      title = 'Not Phish';
      formattedType = 'notPhish';
      break;
    case 'spam':
      title = 'Spam';
      formattedType = 'spam';
      break;
    default:
      break;
  }
  useTitle(`PhishFirewall | Reported phish - ${title}`);

  const { token } = useAuth();
  const { loading, request } = useHttp();
  const { decryptData } = useCrypto();
  const { showToastMessage, fetchPhishReports, phishReports } = useContext(AuthContext);
  const { ref, inView } = useInView({ threshold: 0 });
  const { state } = useLocation();

  const [newPhishReports, setNewPhishReports] = useState([]);
  const [report, setReport] = useState({});
  const [selectedRow, setSelectedRow] = useState(-1);
  const [reportVisible, setReportVisible] = useState({
    visible: false,
    selectedRow
  });
  const [reportsLoading, setReportsLoading] = useState(false);
  const [oneReportLoading, setOneReportLoading] = useState(false);
  const [reportStatus, setReportStatus] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const [previewType, setPreviewType] = useState('');
  const [page, setPage] = useState(1);
  const [currentType, setCurrentType] = useState('');

  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
    setPreviewType('');
  }

  const fetchNewPhishReports = useCallback(async () => {
    try {
      setReportsLoading(true);
      if (token && !state) {
        const fetched = await request(`/back_office/api/phish_reports/by_status/${page}/${type}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptReports = decryptData(fetched);
        setNewPhishReports(decryptReports.userstoriesByStatuses);
        showToastMessage(fetched.error);
        setSelectedRow(-1);
        setReportVisible({
          visible: false,
          selectedRow: -1
        });
        setReportStatus('');
        setReport({});

        return decryptReports;
      } else if (token && state) {
        const stateValue = state?.value;
        const fetched = await request(`/back_office/api/phish_reports/userstory/${stateValue}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptReport = decryptData(fetched);
        setNewPhishReports([decryptReport]);
        setReport(decryptReport);
        setReportStatus(state?.status);
        setSelectedRow(stateValue);
        setReportVisible({
          visible: true,
          selectedRow: stateValue
        });

        showToastMessage(fetched.error, fetched.message, fetched.warning);
        setFileContent(JSON.parse(decryptReport.raw_file_data));

        return decryptReport;
      }
    } finally {
      setReportsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, type, page, state]);


  useEffect(() => {
    if (type === currentType) {
      setNewPhishReports([]);
      fetchNewPhishReports();
    }
  }, [currentType, fetchNewPhishReports, type]);

  const updateReportStatus = (reportId) => {
    const status = newPhishReports.find(r => r.id === reportId)?.status_slug;
    setReportStatus(status || state?.status);
  }

  const getPhishReport = async (selectedRow) => {
    try {
      if (token) {
        setOneReportLoading(true);
        const fetched = await request(`/back_office/api/phish_reports/userstory/${selectedRow}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptReport = decryptData(fetched);
        setReport(decryptReport);
        showToastMessage(fetched.error, fetched.message, fetched.warning);
        setFileContent(JSON.parse(decryptReport.raw_file_data));
        setOneReportLoading(false);
        return decryptReport;
      }
    } catch (error) { setOneReportLoading(false); }
  };

  const updateUserstoryStatus = async (selectedRow, statusValue, version, userEmail, subject, attachments) => {
    try {
      if (token) {
        const data = { statusValue, version, userEmail, subject, attachments };
        const fetched = await request(`/back_office/api/phish_reports/userstory/${selectedRow}`, 'PATCH', data, {
          Authorization: `Bearer ${token}`
        })

        showToastMessage(fetched.error, fetched.message, fetched.warning);
        
        if (fetched.message) {
          fetchPhishReports();
          fetchNewPhishReports();
          setSelectedRow(-1);
          setReportVisible(prev => ({
            ...prev,
            visible: false,
            selectedRow: -1
          }));
        }

        return fetched;
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!inView) {
      setReportVisible({
        visible: false,
        selectedRow: -1
      })
    }
  }, [inView])

  const closePane = () => {
    setReportVisible({
      visible: false,
      selectedRow: -1
    })
  }

  const handlePageChange = (page) => {
    setTimeout(() => {
      setPage(page);
    }, 100)
  };

  useEffect(() => {
    setPage(1);
    setCurrentType(type);
  }, [type]);

  return (
    <>
      <div className='reports-card'>
        <h3 className='reports-card-title'>{`${title} reports`}</h3>
        {!reportsLoading
          ? !newPhishReports.length
            ? <span>{`It's okay, you don't have any ${title} reports yet`}</span>
            : <div>
                <table className="reports-table">
                  <thead className="table-th-reports">
                    <tr className="not-clickable-reports tr-grid-reports tr-reports">
                      <th>Case number</th>
                      <th>Case Owner</th>
                      <th>Contact Email</th>
                      <th>Created At</th>
                      <th>Phish details</th>
                    </tr>
                  </thead>
                  {newPhishReports.length
                    ? <tbody
                        className="table-body table-body-reports"
                      >
                        {newPhishReports.map(r => (
                          <tr
                            key={r.id}
                            className={"tr-grid-reports clickable-row-reports tr-reports"}
                            onClick={() => setSelectedRow(r.id)}
                          >
                            <td
                              className={cn({'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id})}
                              onClick={closePane}
                            >
                              {r.id}
                            </td>
                            <td
                              className={cn({'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id})}
                              onClick={closePane}
                            >
                              {r.user_fullname}
                            </td>
                            <td
                              className={cn({'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id})}
                              onClick={closePane}
                            >
                              {r.user_email}
                            </td>
                            <td
                              className={cn({'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id})}
                              onClick={closePane}
                            >
                              {moment(r.createdAt).utc().format('MM/DD/YYYY HH:mm:ss')}
                            </td>
                            <td className={cn({'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id})}>
                              <button
                                className={cn('reports-button', 'reports-button--close',{
                                  'reports-button--open': reportVisible.visible && reportVisible.selectedRow === r.id,
                                })}
                                onClick={() => {
                                  setSelectedRow(r.id);
                                  setReportVisible(prev => ({
                                    ...prev,
                                    visible: !reportVisible.visible,
                                    selectedRow: r.id
                                  }));
                                  if (!reportVisible.visible && r.id) {
                                    getPhishReport(r.id);
                                  }
                                  updateReportStatus(r.id);
                                }}
                              >
                                {reportVisible.visible && reportVisible.selectedRow === r.id ? 'Close details' : 'View details'}
                              </button>
                            </td>
                              {reportVisible.visible && reportVisible.selectedRow === r.id && (
                                <div ref={ref} className="reports-additional">
                                  {!oneReportLoading ?
                                    <div>
                                      <span className='reports-additional-info'>Additional information</span>
                                      <div className='reports-additional-detailed'>
                                        <ul>
                                          <li>
                                            Status
                                            <pre>{report?.status_extra_info?.name}</pre>
                                          </li>
                                          <li>
                                            Priority
                                            <pre>Medium</pre>
                                          </li>
                                          <li>
                                            Subject
                                            <pre>{report.subject?.trim()}</pre>
                                          </li>
                                          <li>
                                            Description
                                            <pre>
                                              {report.description
                                                ?.split('Phishing email information:')
                                                ?.at(0)
                                                ?.replace(/^[ \t]+/gm, "")
                                                ?.replace(/\n{3,}/g, "\n\n")
                                                ?.trim()
                                              }
                                              </pre>
                                          </li>
                                          <li>
                                            Phishing email information:
                                            <pre>
                                              {report.description
                                                ?.split('Phishing email information:')
                                                ?.at(1)
                                                ?.split('URLs found in phishing email:')
                                                ?.at(0)
                                                ?.replace(/^[ \t]+/gm, "")
                                                ?.replace(/\n{3,}/g, "\n\n")
                                                ?.trim()
                                              }
                                            </pre>
                                          </li>
                                        </ul>

                                        <ul>
                                          <li>
                                            URLs found in phishing email:
                                            <pre>
                                              {report.description
                                                ?.split('URLs found in phishing email:')
                                                ?.at(1)
                                                ?.replace(/^[ \t]+/gm, "")
                                                ?.replace(/\n{3,}/g, "\n\n")
                                                ?.replaceAll('(dot)', '.')
                                                ?.trim()
                                              }
                                            </pre>
                                          </li>
                                        </ul>
                                      </div>

                                      <div className='reports-additional-actions'>
                                        <div className='reports-additional-actions--1'>
                                          <button
                                            disabled={!report.snapshot_preview_url}
                                            onClick={() => {
                                              openModal();
                                              setPreviewType('snapshot');
                                            }}
                                          >
                                            Preview snapshot email
                                          </button>
                                          <button
                                            disabled={!fileContent}
                                            onClick={() => {
                                              openModal();
                                              setPreviewType('rawFile');
                                            }}
                                          >
                                            Preview raw file email
                                          </button>
                                        </div>
                                        <div className='reports-additional-actions--2'>
                                          <span>Identified as:</span>
                                          <div className='button-loader'>
                                            {loading && reportStatus === 'phish' && <span className="loader-small"></span>}
                                            <button
                                              className={cn('reports-additional-actions-button', {
                                                'reports-additional-actions-button--active': reportStatus === 'phish'
                                              })}
                                              onClick={() => {
                                                setReportStatus('phish')
                                                updateUserstoryStatus(
                                                  r.id, 'phish', report.version, r.user_email,
                                                  getSubjectFromDescription(report.description), [report.snapshot_preview_url]
                                                )
                                              }}
                                              // disabled={loading || reportStatus === 'phish'}
                                              disabled
                                            >
                                              Phish
                                            </button>
                                          </div>
                                          <div className='button-loader'>
                                            {loading && reportStatus === 'not-phish' && <span className="loader-small"></span>}
                                            <button
                                              className={cn('reports-additional-actions-button', {
                                                'reports-additional-actions-button--active': reportStatus === 'not-phish'
                                              })}
                                              onClick={() => {
                                                setReportStatus('not-phish')
                                                updateUserstoryStatus(
                                                  r.id, 'not-phish', report.version, r.user_email,
                                                  getSubjectFromDescription(report.description), [report.snapshot_preview_url]
                                                )
                                              }}
                                              // disabled={loading || reportStatus === 'not-phish'}
                                              disabled
                                            >
                                              Not phish
                                            </button>
                                          </div>
                                          <div className='button-loader'>
                                            {loading && reportStatus === 'spam' && <span className="loader-small"></span>}
                                            <button
                                              className={cn('reports-additional-actions-button', {
                                                'reports-additional-actions-button--active': reportStatus === 'spam'
                                              })}
                                              onClick={() => {
                                                setReportStatus('spam')
                                                updateUserstoryStatus(
                                                  r.id, 'spam', report.version, r.user_email,
                                                  getSubjectFromDescription(report.description), [report.snapshot_preview_url]
                                                )
                                              }}
                                              // disabled={loading || reportStatus === 'spam'}
                                              disabled
                                            >
                                              Spam
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                      <div className='reports-additional-actions--comment'>
                                        {report?.comments?.map(({comment, date}, i) => (
                                          <div key={i} className='reports-additional-submit--comment'>
                                            <span>{comment?.split(',')?.at(0)?.replace('Revieved', 'Reviewed')}</span>
                                            <span>{moment(date).format('MM/DD/YYYY HH:mm')}</span>
                                            <br />
                                          </div>
                                        ))}
                                      </div>
                                      <div className='reports-additional-submit'>
                                        <button
                                          className='reports-additional-submit--cancel'
                                          onClick={() => {
                                            setSelectedRow(-1);
                                            setReportVisible(prev => ({
                                              ...prev,
                                              visible: false,
                                              selectedRow: -1
                                            }));
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  : <Loader/>}
                                </div>
                              )}
                          </tr>
                        ))}
                      </tbody>
                    : <tbody></tbody>}
                </table>
              </div>
          : <Loader />}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            closeTimeoutMS={200}
          >
            <div className="reports-modal">
              <button onClick={closeModal} className="reports-modal-close"></button>
              <h4 className="reports-modal-cancel-title">Email preview</h4>
              <div className="reports-modal-cancel-subscription">
                {previewType === 'snapshot' &&
                  <img src={report.snapshot_preview_url} alt='Email preview'></img>
                }
                {previewType === 'rawFile' &&
                  <JsonView
                    src={fileContent}
                    theme='shapeshifter:inverted'
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                }
              </div>
              <div className="reports-modal-gotit-button">
                <button
                  onClick={closeModal}
                >
                  Got It!
                </button>
              </div>
            </div>
          </Modal>
      </div>

      {!state && !loading && newPhishReports.length > 1 &&
        <Pagination
          className="pagination-bar"
          siblingCount={2}
          currentPage={page}
          totalCount={phishReports?.[formattedType] || 0}
          pageSize={itemsOnPage}
          onPageChange={handlePageChange}
        />
      }
    </>
  )
};
