import React, { useContext, useState, useCallback, useEffect } from 'react';
import Select, { components } from "react-select";
import Modal from 'react-modal';
import cn from 'classnames';
import { Loader } from '../../components/Loader/Loader';
import { AuthContext } from '../../context/AuthContext';
import { useHttp } from '../../hooks/http.hook';
import { useTitle } from '../../hooks/title.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import { SSOConfig } from '../../components/SSOConfig/SSOConfig';
import { roles } from '../../config/config';
import colourStyles from '../../../src/styles/colour-style';
import { customStyles } from '../../styles/customStyles';
import { CustomizedTooltipEditTenantPermissions } from '../../components/Tooltips/Tooltip';
import './DetailsPage.css';

export const AccountDetails = () => {
  useTitle("PhishFirewall | Details");

  const { loading, request, secondaryLoading, requestWithSecondaryLoading } = useHttp();
  const { user, showToastMessage } = useContext(AuthContext);
  const { token } = useAuth();
  const { decryptData, encryptData } = useCrypto();

  const [accountUsers, setAccountUsers] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalRemoveIsOpen, setModalRemoveIsOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState({ email: '' });
  const [selectedRoleOption, setSelectedRoleOption] = useState([]);
  const [selectedTenantsOption, setSelectedTenantsOption] = useState([]);
  const [roleError, setRoleError] = useState('');
  const [tenantsError, setTenantsError] = useState('');
  const [accountUserId, setAccountUserId] = useState('');
  const [syncAccessLoading, setSyncAccessLoading] = useState(false);
  const [removeAdminLoading, setRemoveAdminLoading] = useState(false);
  const [adminAccessChecked, setAdminAccessChecked] = useState(false);
  const [falsePositiveActionAccess, setFalsePositiveActionAccess] = useState(false);
  const [allTenantsAccess, setAllTenantsAccess] = useState(false);
  const [config, setConfig] = useState(null);
  const [card, setCard] = useState(1);

  const fetchConfig = useCallback(async () => {
    try {
      if (token) {
        const fetched = await requestWithSecondaryLoading('/back_office/api/user/sso_config', 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptConfig = decryptData(fetched.config);

        if (decryptConfig) {
          setCard(3);
          setConfig(decryptConfig);
        }

        return decryptConfig;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    fetchConfig()
  }, [fetchConfig]);

  const userRole = user.role?.split('-')?.join(' ');

  const validateRoleSelect = (data) => {
    if (!data.length) setRoleError("Please select a role")
    else setRoleError('')
  }

  const validateTenantSelect = (data) => {
    if (!data.length) {
      switch (selectedRoleOption.at(0)?.value) {
        case 'tenant-admin':
          setTenantsError("Please select one or more tenants")
          break;
        case 'admin':
          setTenantsError("Please select a tenant")
          break;
        default:
          setTenantsError("Please select one or more tenants")
          break;
      }
    } else setTenantsError('')
  }

  const changeInviteHandler = (event) => {
    setInviteEmail({ [event.target.name]: event.target.value.trim() })
  };

  const openModal = () => {
    setIsOpen(true);
  }

  const openModalEdit = () => {
    setModalEditIsOpen(true);
  }

  const openModalRemove = () => {
    setModalRemoveIsOpen(true);
  }

  const closeModal = () => {
    setSelectedTenantsOption([]);
    setSelectedRoleOption([]);
    setModalEditIsOpen(false);
    setIsOpen(false);
    setRoleError('');
    setTenantsError('');
    setInviteEmail({ email: '' });
    setAccountUserId('');
    setAdminAccessChecked(false);
    setFalsePositiveActionAccess(false);
    setAllTenantsAccess(false);
  }

  const closeRemoveModal = () => {
    setModalRemoveIsOpen(false);

    setTimeout(() => {
      setAccountUserId('');
    }, 200)
  }

  const fetchAccountUsersAndTenants = useCallback(async () => {
    try {
      if (token) {
        const users = await request('/back_office/api/user/account_users', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptUsers = decryptData(users);
        setAccountUsers(decryptUsers);

        const tenants = await request('/back_office/api/user/all_active_tenants', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        setTenants(decryptData(tenants));
        return decryptUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const inviteHandler = async (
    e, email, role, tenants, syncAccess, allTenantsAccess, falsePositiveActionAccess
  ) => {
    e.preventDefault();
    try {
      if (inviteEmail.email) {
        const preparedData = {
          email,
          role: role.at(0).value,
          tenantsIds: !allTenantsAccess ? tenants.map(t => t.value) : [],
          syncAccess,
          allTenantsAccess,
          falsePositiveActionAccess
        }
        const data = encryptData(preparedData);
        const res = await request('/back_office/api/user/register_by_invation', 'POST', { data });

        showToastMessage(res.error, res.message);
        if (!res.error) closeModal();
      }
      setInviteEmail({ email: '' })
      if (!inviteEmail.email) closeModal();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchAccountUsersAndTenants()
  }, [fetchAccountUsersAndTenants]);

  useEffect(() => {
    if (!tenants.length) setSelectedRoleOption([{ value: 'tenant-admin', roleLabel: 'Tenant Admin', label: 'Tenant Admin' }])
    else if (user.role === 'admin' || user.role === 'tenant-admin') setSelectedRoleOption([{ value: 'admin', roleLabel: 'Admin', label: 'Admin' }])
    else setSelectedRoleOption([])
  }, [tenants.length, user.role])

  useEffect(() => { }, [tenants.length])

  useEffect(() => {
    setAdminAccessChecked(!!accountUsers.find(user => user.id === accountUserId)?.tenants?.syncAccess);
    setFalsePositiveActionAccess(!!accountUsers.find(user => user.id === accountUserId)?.tenants?.falsePositiveActionAccess);
    if (selectedRoleOption.at(0)?.value === 'admin') {
      setAdminAccessChecked(false);
      setFalsePositiveActionAccess(false);
    }
  }, [accountUserId, accountUsers, selectedRoleOption])

  const rolesOptionList = roles.map(role => (
    { value: role.value, roleLabel: role.name, label: role.name }
  ))

  let tenantsOptionList;
  switch (user.role) {
    case 'owner':
      tenantsOptionList = tenants.map(tenant => (
        {
          value: tenant.id, tenantLabel: tenant.name, label: tenant.name, caption: tenant.type
        }
      ))
      break;
    case 'tenant-admin':
      tenantsOptionList = user.tenants.allTenantsAccess
        ? tenants.map(tenant => (
          {
            value: tenant.id, tenantLabel: tenant.name, label: tenant.name, caption: tenant.type
          }
        ))
        : tenants
          .filter(t => user.tenants.tenantsIds.includes(t.id))
          .map(tenant => (
            { value: tenant.id, tenantLabel: tenant.name, label: tenant.name, caption: tenant.type }
          ))
      break;
    case 'admin':
      tenantsOptionList = tenants
        .filter(t => user.tenants.tenantsIds.includes(t.id))
        .map(tenant => (
          { value: tenant.id, tenantLabel: tenant.name, label: tenant.name, caption: tenant.type }
        ))
      break;

    default:
      break;
  }

  const Option = (props) => {
    const { roleLabel, tenantLabel, caption } = props.data;

    return (
      <components.Option {...props}>
        <div className={cn({ 'role-label': roleLabel })}>{roleLabel}</div>
        <div className={cn({ 'tenant-label': tenantLabel })}>{tenantLabel}</div>
        {caption && <div className='caption'>{caption?.charAt(0)?.toUpperCase() + caption?.slice(1)}</div>}
      </components.Option>
    );
  };

  const removeAdmin = async (accountUserId) => {
    setModalRemoveIsOpen(false);

    setTimeout(async () => {
      setRemoveAdminLoading(true);
      setAccountUserId(accountUserId);

      try {
        if (token) {
          const data = encryptData({ adminId: accountUserId })
          const res = await fetch(`/back_office/api/user/remove_admin`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ data })
            })
          const response = await res.json();

          setTimeout(() => {
            setAccountUserId('');
            setRemoveAdminLoading(false);
            showToastMessage(response.error, response.message);
            fetchAccountUsersAndTenants();
            setTimeout(() => {
              window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth',
              });
            }, 1000)
          }, 2000)
        }
      } catch (error) {
        setAccountUserId('');
        setRemoveAdminLoading(false);
      }
    }, 200);
  }

  const updateAdmin = async (
    accountUserId, role, tenants, syncAccess, allTenantsAccess, falsePositiveActionAccess
  ) => {
    try {
      if (token) {
        const preparedData = {
          role: role.at(0).value,
          tenantsIds: !allTenantsAccess ? tenants.map(t => t.value) : [],
          syncAccess,
          allTenantsAccess,
          falsePositiveActionAccess
        }
        const data = encryptData(preparedData);
        const res = await request(`/back_office/api/user/update_admin/${accountUserId}`, 'PATCH', { data });

        setAccountUserId('');
        fetchAccountUsersAndTenants();
        showToastMessage(res.error, res.message);
        if (res.message) {
          closeModal();
          setTimeout(() => {
            window.scrollTo({
              top: document.documentElement.scrollHeight,
              behavior: 'smooth',
            });
          }, 1000)
        }
      }
    } catch (error) {
      setAccountUserId('');
      setSyncAccessLoading(false);
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="details-main">
      <h2 className="details-main-title">
        Account details
      </h2>

      <div className='account-details-card'>
        <div className='details-action'>
          <h3 className='details-action-title'>User profile</h3>
        </div>

        <ul className='details-account-list'>
          <li className='details-item'>
            First Name
            <p className='details-value'>{user.firstName}</p>
          </li>
          <li className='details-item'>
            Last Name
            <p className='details-value'>{user.lastName}</p>
          </li>
          <li className='details-item'>
            Role
            <p className='details-value details-value-role'>
              {userRole?.charAt(0)?.toUpperCase() + userRole?.slice(1)}
            </p>
          </li>
        </ul>

        <ul className='details-account-list'>
          <li className='details-item'>
            Your company name
            <p className='details-value'>{user.companyName}</p>
          </li>
          <li className='details-item'>
            Your email
            <p className='details-value'>{user.email}</p>
          </li>
          <li className='details-item'>
            Phone number
            <p className='details-value'>{!!user.phoneNumber && '+' + user.phoneNumber}</p>
          </li>
        </ul>
      </div>

      {secondaryLoading
        ? <Loader />
        : <SSOConfig
            config={config}
            setConfig={setConfig}
            fetchConfig={fetchConfig}
            card={card}
            setCard={setCard}
          />
      }

      <div className='account-details-card-users'>
        <div className='details-action'>
          <h3 className='details-action-title'>Account users</h3>
          {(tenants.length || (!tenants.length && (user.role !== 'admin' && user.role !== 'tenant-admin'))) &&
            <>
              <button
                className='details-action-modal'
                onClick={() => {
                  setSelectedRoleOption(tenants.length
                    ? user.role === 'admin' || user.role === 'tenant-admin'
                      ? [rolesOptionList[1]]
                      : []
                    : [rolesOptionList[0]]
                  );
                  openModal();
                }}
              >
                Invite an admin
              </button>
            </>
          }
        </div>
        <table className="account-details-table">
          <thead className="table-th-account-details">
            <tr style={{ 'width': '100%' }} className="not-clickable-account-details tr-grid-account-details tr-account-details">
              <th style={{ 'width': '12%' }}>First name</th>
              <th style={{ 'width': '10%' }}>Last name</th>
              <th style={{ 'width': '15%' }}>Email address</th>
              <th style={{ 'width': '10%' }}>Phone number</th>
              <th style={{ 'width': '30%' }}>Access to Tenants</th>
              <th style={{ 'width': '18%' }}>Role</th>
              <th
                style={{ 'width': '5%' }}
                className={cn('slider-checkbox-th', {
                  'slider-checkbox-th--disabled': user.role === 'admin' || (user.role === 'tenant-admin' && !user.tenants.allTenantsAccess)
                })}
              >
                <p>Edit</p>
              </th>
              {user.role === 'owner' &&
                <th className="last-actions">Action</th>
              }
            </tr>
          </thead>
          <tbody
            className="table-body-account-details"
          >
            {accountUsers.map(accountUser => {
              const accountRole = accountUser.role.split('-').join(' ');
              return (
                <tr
                  key={accountUser.id}
                  className="tr-grid-account-details clickable-row-account-details tr-account-details"
                >
                  <td>{accountUser.firstName}</td>
                  <td>{accountUser.lastName}</td>
                  <td>{accountUser.email}</td>
                  <td>{!!accountUser.phoneNumber && '+' + accountUser.phoneNumber}</td>
                  <td>{accountUser?.tenantsNames?.length && accountUser.role !== 'owner' && !accountUser.tenants.allTenantsAccess
                    ? accountUser.tenantsNames.map(tenant => ' ' + tenant).toString()
                    : accountUser.role === 'owner' || (accountUser.role === 'tenant-admin' && accountUser.tenants.allTenantsAccess)
                      ? 'All'
                      : 'None'
                  }
                  </td>
                  <td>
                    {accountRole.charAt(0).toUpperCase() + accountRole.slice(1)}
                    {accountUser.id === user.id && <span> (You)</span>}
                  </td>
                  <td className={cn('slider-checkbox-td', {
                    'slider-checkbox-td--disabled': user.role === 'admin' || (user.role === 'tenant-admin' && !user.tenants.allTenantsAccess)
                  })}>
                    {((accountUser.role !== 'owner' && (user.role === 'owner' || user.role === 'tenant-admin'))
                        || (user.role === 'tenant-admin'
                          && accountUser.role === 'admin'
                          && accountUser.tenants.tenantsIds.every(item => user.tenants.tenantsIds.includes(item)))
                      ) &&
                        <div className={cn({
                          'role-edit-button--disabled': (user.role === 'tenant-admin' && !user?.tenants?.allTenantsAccess)
                            || (user.role === 'tenant-admin' && accountUser?.tenants?.allTenantsAccess)
                            || user.role === 'admin'
                        })}>
                          <CustomizedTooltipEditTenantPermissions
                            text1={'Permissions:'}
                            text2={'New sync access'}
                            text3={'Actions False Positive status change access'}
                            text4={'All tenants access'}
                            text2condition={accountUser?.tenants?.syncAccess}
                            text3condition={accountUser?.tenants?.falsePositiveActionAccess}
                            text4condition={accountUser?.tenants?.allTenantsAccess}
                            showFragment={(user.role === 'owner' && accountUser.role === 'tenant-admin')}
                            component={
                              <button
                                className={cn('role-edit-button', {
                                  'role-edit-button-focus': accountUser.id === accountUserId && !syncAccessLoading,
                                  'role-edit-button-remove': accountUser.id === accountUserId && removeAdminLoading
                                })}
                                onClick={() => {
                                  const filteredTenants = tenantsOptionList.filter(tenant => accountUser?.tenants?.tenantsIds.includes(tenant.value));
                                  const selectedRole = accountUsers.find(user => user.id === accountUser.id)?.role;
                                  const selectedUserAllTenantAccess = accountUsers.find(user => user.id === accountUser.id)?.tenants?.allTenantsAccess;
                                  setSelectedRoleOption([
                                    rolesOptionList.find(option => option.value === selectedRole)
                                  ])
                                  setSelectedTenantsOption(
                                    !selectedUserAllTenantAccess
                                      ? selectedRole === 'tenant-admin' ? filteredTenants : filteredTenants?.at(0)
                                      : []
                                  )
                                  setAllTenantsAccess(selectedUserAllTenantAccess || false);
                                  setAccountUserId(accountUser.id);
                                  openModalEdit()
                                }}
                              />
                            }
                          />
                        </div>
                    }
                  </td>
                  {user.role === 'owner' && accountUser.role !== 'owner' &&
                    <td className="last-actions">
                      <p
                        className={cn("button-remove", {
                          'success': accountUser.id === accountUserId && removeAdminLoading
                        })}
                        role="button"
                        onClick={() => {
                          openModalRemove()
                          setAccountUserId(accountUser.id);
                        }}
                      >
                        <span className="remove">remove</span>
                        <div className="icon-remove">
                          <i className="fa fa-remove"></i>
                          <i className="fa fa-check"></i>
                        </div>
                      </p>
                    </td>
                  }
                </tr>
              )
            })
            }
          </tbody>
        </table>
      </div>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button onClick={closeModal} className="modal-close"></button>
          <form className="form edit-access-form">
            <div className="title">
              <span className="card-title">
                {selectedRoleOption?.at(0)?.value === 'tenant-admin' ? `Invite a ${selectedRoleOption?.at(0)?.roleLabel}` : 'Invite an Admin'}
              </span><br />
              <span className="card-subtitle">
                Please enter the admin’s email and we will send him login instructions.
              </span>
            </div>

            <div className="card-content">
              <div className="input-field">
                <label htmlFor="email">Email</label><br />
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder='Enter an email for invitation'
                  className="input"
                  onChange={changeInviteHandler}
                />
              </div>
            </div>

            <div className='role-select-container'>
              <span>Role</span>
              <Select
                id='select'
                name='role-select'
                className='role-select'
                options={rolesOptionList}
                components={{ Option }}
                placeholder="Select role"
                isDisabled={!tenants.length || user.role === 'admin' || user.role === 'tenant-admin'}
                value={tenants.length
                  ? user.role === 'admin' || user.role === 'tenant-admin'
                    ? rolesOptionList[1]
                    : selectedRoleOption
                  : rolesOptionList[0]
                }
                defaultValue={tenants.length
                  ? user.role === 'admin' || user.role === 'tenant-admin'
                    ? rolesOptionList[1]
                    : selectedRoleOption
                  : rolesOptionList[0]
                }
                onChange={(data) => {
                  setSelectedRoleOption([data])
                  setSelectedTenantsOption([])
                  validateRoleSelect([data])
                  setAllTenantsAccess(false)
                }}
                isSearchable={true}
                closeMenuOnSelect={true}
                styles={colourStyles}
                onBlur={(e) => {
                  validateRoleSelect(selectedRoleOption)
                }}
              />
              {roleError && <p className='err'>{roleError}</p>}
            </div>

            {selectedRoleOption?.at(0)?.value === 'tenant-admin' && user.role === 'owner' &&
              <div>
                <div className='checkbox-select-container'>
                  <label
                    className={"slider-checkbox slider-checkbox-edit-admin slider-checkbox-permissions"}
                  >
                    <input
                      type="checkbox"
                      className='checkbox-input'
                      checked={adminAccessChecked}
                      onChange={() => setAdminAccessChecked(!adminAccessChecked)}
                    />
                    <span className={cn("slider", {
                      "slider-checkbox-disabled": false,
                    })} />
                  </label>
                  <span>New sync access</span>
                </div>

                <div className='checkbox-select-container'>
                  <label
                    className={"slider-checkbox slider-checkbox-edit-admin slider-checkbox-permissions"}
                  >
                    <input
                      type="checkbox"
                      className='checkbox-input'
                      checked={falsePositiveActionAccess}
                      onChange={() => setFalsePositiveActionAccess(!falsePositiveActionAccess)}
                    />
                    <span className={cn("slider", {
                      "slider-checkbox-disabled": false,
                    })} />
                  </label>
                  <span>Actions False Positive status change access</span>
                </div>

                <div className='checkbox-select-container'>
                  <label
                    className={"slider-checkbox slider-checkbox-edit-admin slider-checkbox-permissions"}
                  >
                    <input
                      type="checkbox"
                      className='checkbox-input'
                      checked={allTenantsAccess}
                      onChange={() => setAllTenantsAccess(!allTenantsAccess)}
                    />
                    <span className={cn("slider", {
                      "slider-checkbox-disabled": false,
                    })} />
                  </label>
                  <span>All tenants access</span>
                </div>
              </div>
            }

            {tenants.length
              ? <div className='tenant-select-container'>
                <span>Tenant(s)</span>
                <Select
                  id='select'
                  name='tenant-select'
                  className='tenant-select'
                  options={tenantsOptionList}
                  components={{ Option }}
                  placeholder={allTenantsAccess ? "All Tenants" : "Select tenant"}
                  value={selectedTenantsOption}
                  onChange={(data) => {
                    selectedRoleOption?.at(0)?.value === 'tenant-admin'
                      ? setSelectedTenantsOption(data)
                      : setSelectedTenantsOption([data])
                    if (user.role !== 'admin') {
                      validateRoleSelect(selectedRoleOption)
                      validateTenantSelect([data])
                    }
                  }}
                  isSearchable={true}
                  closeMenuOnSelect={selectedRoleOption.at(0)?.value === 'admin'}
                  isMulti={selectedRoleOption.at(0)?.value === 'tenant-admin'}
                  styles={colourStyles}
                  isDisabled={allTenantsAccess}
                  onBlur={(e) => {
                    selectedRoleOption.at(0)?.value === 'admin' && validateTenantSelect(selectedTenantsOption)
                  }}
                />
                {tenantsError && <p className='err'>{tenantsError}</p>}
              </div>
              : !tenants.length && selectedRoleOption.at(0)?.value === 'tenant-admin'
                ? <p className='tenant-select-description'>
                  Since your company doesn't have any tenants created, <strong>Tenant Admin</strong> will have no access to the any tenant.<br />
                  <br />
                  Check <strong>"All tenants access"</strong> to allow interaction with all company data<br />
                  or <strong>"New sync access"</strong> to allow new sync creation and interaction with created tenant.
                </p>
                : <p className='tenant-select-description'>
                  Since your company doesn't have any tenants created,<br />
                  you cannot add an <strong>Admin</strong>.
                </p>
            }

            <div className="card-action-modal">
              {inviteEmail.email
                ? <button
                  disabled={loading
                    || !selectedRoleOption.length
                    || (tenants.length
                      && !selectedTenantsOption.length
                      && selectedRoleOption.at(0)?.value === 'admin'
                    )
                  }
                  onClick={(e) => inviteHandler(
                    e, inviteEmail.email, selectedRoleOption, selectedTenantsOption,
                    adminAccessChecked, allTenantsAccess, falsePositiveActionAccess
                  )}
                >
                  Send invite
                </button>
                : <button
                  onClick={closeModal}
                >
                  Close
                </button>}
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={modalEditIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button onClick={closeModal} className="modal-close"></button>
          <form className="form edit-access-form">
            <div className="title">
              <span className="card-title">
                {accountUsers.find(user => user.id === accountUserId)?.role === 'tenant-admin' ? 'Edit a Tenant Admin permissions' : 'Edit an Admin permissions'}
              </span><br />
            </div>

            <div className='role-select-container'>
              <span>Role</span>
              <Select
                id='select'
                name='role-select'
                className='role-select'
                options={rolesOptionList}
                components={{ Option }}
                placeholder="Select role"
                isDisabled={!tenants.length || user.role === 'admin'}
                value={selectedRoleOption?.length
                  ? selectedRoleOption
                  : rolesOptionList.find(option => option.value === accountUsers.find(user => user.id === accountUserId)?.role)
                }
                onChange={(data) => {
                  setSelectedRoleOption([data])
                  setSelectedTenantsOption([])
                  setAllTenantsAccess(false)
                  setAdminAccessChecked(false)
                  setFalsePositiveActionAccess(false)
                  if (selectedRoleOption.at(0)?.value === 'tenant-admin') setTenantsError('')
                }}
                isSearchable={true}
                closeMenuOnSelect={true}
                styles={colourStyles}
              />
            </div>
            {selectedRoleOption.at(0)?.value === 'tenant-admin' && user.role === 'owner' &&
              <div>
                <div className='checkbox-select-container'>
                  <label
                    className={"slider-checkbox slider-checkbox-edit-admin slider-checkbox-permissions"}
                  >
                    <input
                      type="checkbox"
                      className='checkbox-input'
                      checked={adminAccessChecked}
                      onChange={() => setAdminAccessChecked(!adminAccessChecked)}
                    />
                    <span className={cn("slider", {
                      "slider-checkbox-disabled": false,
                    })} />
                  </label>
                  <span>New sync access</span>
                </div>

                <div className='checkbox-select-container'>
                  <label
                    className={"slider-checkbox slider-checkbox-edit-admin slider-checkbox-permissions"}
                  >
                    <input
                      type="checkbox"
                      className='checkbox-input'
                      checked={falsePositiveActionAccess}
                      onChange={() => setFalsePositiveActionAccess(!falsePositiveActionAccess)}
                    />
                    <span className={cn("slider", {
                      "slider-checkbox-disabled": false,
                    })} />
                  </label>
                  <span>Actions False Positive status change access</span>
                </div>

                <div className='checkbox-select-container'>
                  <label
                    className={"slider-checkbox slider-checkbox-edit-admin slider-checkbox-permissions"}
                  >
                    <input
                      type="checkbox"
                      className='checkbox-input'
                      checked={allTenantsAccess}
                      onChange={() => {
                        setSelectedTenantsOption([])
                        setAllTenantsAccess(!allTenantsAccess)
                      }
                      }
                    />
                    <span className={cn("slider", {
                      "slider-checkbox-disabled": false,
                    })} />
                  </label>
                  <span>All tenants access</span>
                </div>
              </div>
            }
            {tenants.length
              ? <div className='tenant-select-container'>
                <span>Tenant(s)</span>
                <Select
                  id='select'
                  name='tenant-select'
                  className='tenant-select'
                  options={tenantsOptionList}
                  components={{ Option }}
                  placeholder={allTenantsAccess ? "All Tenants" : "Select tenant"}
                  value={selectedTenantsOption}
                  onChange={data => {
                    selectedRoleOption.at(0)?.value === 'tenant-admin'
                      ? setSelectedTenantsOption(data)
                      : setSelectedTenantsOption([data])
                    if (user.role !== 'admin') {
                      validateRoleSelect(selectedRoleOption)
                      validateTenantSelect([data])
                    }
                  }}
                  isSearchable={true}
                  closeMenuOnSelect={selectedRoleOption.at(0)?.value === 'admin'}
                  isMulti={selectedRoleOption.at(0)?.value === 'tenant-admin'}
                  styles={colourStyles}
                  isDisabled={allTenantsAccess}
                  onBlur={(e) => {
                    selectedRoleOption.at(0)?.value === 'admin' && validateTenantSelect(selectedTenantsOption)
                  }}
                />
                {tenantsError && <p className='err'>{tenantsError}</p>}
              </div>
               : !tenants.length && selectedRoleOption.at(0)?.value === 'tenant-admin'
               ? <p className='tenant-select-description'>
                 Since your company doesn't have any tenants created, <strong>Tenant Admin</strong> will have no access to the any tenant.<br />
                 <br />
                 Check <strong>"All tenants access"</strong> to allow interaction with all company data<br />
                 or <strong>"New sync access"</strong> to allow new sync creation and interaction with created tenant.
               </p>
               : <p className='tenant-select-description'>
                 Since your company doesn't have any tenants created,<br />
                 you cannot add an <strong>Admin</strong>.
               </p>
            }

            <div className="card-action-modal">
              <button
                type='button'
                disabled={loading
                  || !selectedRoleOption.length
                  || (tenants.length
                    && !selectedTenantsOption.length
                    && selectedRoleOption.at(0)?.value === 'admin'
                  )
                }
                onClick={() => updateAdmin(
                  accountUserId, selectedRoleOption, selectedTenantsOption,
                  adminAccessChecked, allTenantsAccess, falsePositiveActionAccess
                )}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={modalRemoveIsOpen}
          onRequestClose={closeRemoveModal}
          style={customStyles}
          contentLabel="Example Modal"
          closeTimeoutMS={200}
        >
          <button onClick={closeRemoveModal} className="modal-close"></button>
          <form className="form">
            <div className="title">
              <span className="card-title ">
                {accountUsers.find(user => user.id === accountUserId)?.role === 'tenant-admin' ? `Remove a Tenant Admin` : 'Remove an Admin'}
              </span><br />
            </div>

            <div className='remove-form'>
              <p>
                This action is irreversible!<br />
              </p>
              <p>
                Are you sure you want to delete the <strong>{accountUsers.find(user => user.id === accountUserId)?.email}</strong> account from your company?
              </p>
            </div>

            <div className="card-action-modal-remove">
              <button
                type='button'
                onClick={() => removeAdmin(accountUserId)}
              >
                Yes, delete!
              </button>
              <button
                type='button'
                onClick={closeRemoveModal}
              >
                No
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  )
}
