import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useSortableData } from '../../hooks/sort.hook';
import { getClassNamesFor } from '../../common/getClassNamesFor';
import edit from '../.././images/icons/Pen.svg';
import { useCrypto } from '../../hooks/crypto.hook';
import './UsersGroup.css';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import azure from '../../images/icons/Azure.svg';
import google from '../../images/icons/Google (colors).svg';
import csv from '../../images/icons/File Red.svg';
import filter from '../../images/icons/filter-icon.svg';
import arrowdown from '../.././images/icons/caret-down.svg';
import { AuthContext } from '../../context/AuthContext';
import { UsersFilters } from '../../components/Filters/UsersFilters';

export const UsersGroup = () => {
  const { loading, request } = useHttp();
  const { token } = useAuth();
  const { decryptData } = useCrypto();
  const { group } = useParams();
  const [users, setUsers] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [exclusionsShown, setExclusionsShown] = useState(false);
  const [filtersShown, setFiltersShown] = useState(false);
  const [openGroupTenantId, setOpenGroupTenantId] = useState(null);
  const [exclusions, setExclusions] = useState([]);
  const { allUsers, groupsLogs } = useContext(AuthContext);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const { items, requestSort, sortConfig } = useSortableData(filteredUsers);

  const navigate = useNavigate();

  const normalizedGroupName = group?.split('_').join(' ');

  const fetchAllUsers = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          `/back_office/api/groups/${group}/users`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptUsers = decryptData(fetched);
        if (!decryptUsers.length) {
          return navigate('/back_office/groups');
        }
        setUsers(decryptUsers);
        setFilteredUsers(decryptUsers);
        setFiltersShown(false)
        return decryptUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, group]);

  const fetchAllTenants = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          `/back_office/api/user/get_all_tenants`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        const decryptUsers = decryptData(fetched);

        setTenants(decryptUsers);

        return decryptUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const getExclusions = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          `/back_office/api/groups/${group.split(' ').join('_')}/exclusions`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptExclusions = decryptData(fetched);

        setExclusions(decryptExclusions);
        setOpenGroupTenantId(null)
        return decryptExclusions;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, tenants, group]);

  const azureTenants = tenants?.filter((t) => t.type === 'azure');
  const googleTenants = tenants?.filter((t) => t.type === 'google');

  const azureSyncedTenants = azureTenants?.filter(
    (tenant) =>
      tenant.groupsMap.find((g) => g.systemGroup === normalizedGroupName)
        ?.sourceGroups?.filter(e => !!e).length
  );
  const googleSyncedTenants = googleTenants?.filter(
    (tenant) =>
      tenant.groupsMap.find((g) => g.systemGroup === normalizedGroupName)
        ?.sourceGroups?.filter(e => !!e).length
  );

  const getNamesByIds = (tenant, ids) => {
    const { name, type } = tenant;

    const groups = groupsLogs[type]?.find(
      (l) => l.tenantName === name
    )?.userGroups;
    const names = groups
      ?.filter((g) => ids.includes(g.id))
      .map((e) => e.name)
      .join(', ');

    return names;
  };

  const getUserNamesByEmail = (emails) => {
    const members = allUsers.filter((u) => emails.includes(u.email));
    const names = members.map((e, i) => (
      <li key={i}>{`${e.firstName} ${e.secondName} <${e.email}>`}</li>
    ));

    return names;
  };

  const getExclusionByTenant = (tenantName) => {
    const exclusion = exclusions.filter((e) => e.tenantName === tenantName);

    return exclusion.map((e) => e.userEmail);
  };

  useEffect(() => {
    fetchAllTenants();
  }, [fetchAllTenants]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  useEffect(() => {
    getExclusions();
  }, [getExclusions]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {!loading && (
        <div className="users-main">
          <div>
            <div className="group-button-container">
              <div>
                {(!!azureSyncedTenants.length ||
                  !!googleSyncedTenants.length) && (
                    <h4 className="group-sync-title">Synced</h4>
                  )}
                <ul>
                  {azureSyncedTenants.map((tenant) => (
                    <li key={tenant.id}>
                      <div className="group-sync-tenant">
                        <img src={azure} alt="Logo" className="logo-group" />
                        <div>
                          <button
                            className="excl-button"
                            onClick={() =>
                              openGroupTenantId !== tenant.id ? setOpenGroupTenantId(tenant.id) : setOpenGroupTenantId(null)
                            }
                          >
                            <div className="excl-list-title">
                            {tenant.name}
                              {openGroupTenantId !== tenant.id ? (
                                <img src={arrowdown} alt="arrow down" />
                              ) : (
                                <img
                                  style={{ transform: 'rotate(180deg)' }}
                                  src={arrowdown}
                                  alt="arrow up"
                                />
                              )}
                            </div>
                          </button>

                          {openGroupTenantId === tenant.id && (
                          <div className="excl-list-title">
                              <span>{`Groups: `}</span>
                              {getNamesByIds(
                            tenant,
                            tenant.groupsMap.find(
                              (g) => g.systemGroup === normalizedGroupName
                            )?.sourceGroups
                          )}
                          </div>)}
                        </div>
                      </div>
                      {!!getExclusionByTenant(tenant.name).length && (
                        <div className="group-sync-tenant">
                          {
                            <button
                              className="excl-button"
                              onClick={() =>
                                setExclusionsShown((prev) => !prev)
                              }
                            >
                              <div className="excl-list-title">
                                <strong>Exclusions</strong>
                                {!exclusionsShown ? (
                                  <img src={arrowdown} alt="arrow down" />
                                ) : (
                                  <img
                                    style={{ transform: 'rotate(180deg)' }}
                                    src={arrowdown}
                                    alt="arrow up"
                                  />
                                )}
                              </div>
                            </button>
                          }
                        </div>
                      )}
                      {exclusionsShown && (
                        <ul className="excl-list">
                          {getUserNamesByEmail(
                            getExclusionByTenant(tenant.name)
                          )}
                        </ul>
                      )}
                    </li>
                  ))}

                  {googleSyncedTenants.map((tenant) => (
                    <li key={tenant.id}>
                      <div className="group-sync-tenant">
                        <img src={google} alt="Logo" className="logo-group" />
                        <div>
                          <button
                            className="excl-button"
                            onClick={() =>
                              openGroupTenantId !== tenant.id ? setOpenGroupTenantId(tenant.id) : setOpenGroupTenantId(null)
                            }
                          >
                            <div className="excl-list-title">
                            {tenant.name}
                              {openGroupTenantId !== tenant.id ? (
                                <img src={arrowdown} alt="arrow down" />
                              ) : (
                                <img
                                  style={{ transform: 'rotate(180deg)' }}
                                  src={arrowdown}
                                  alt="arrow up"
                                />
                              )}
                            </div>
                          </button>

                          {openGroupTenantId === tenant.id && (
                          <div className="excl-list-title">
                              {`Groups: `}
                              {getNamesByIds(
                            tenant,
                            tenant.groupsMap.find(
                              (g) => g.systemGroup === normalizedGroupName
                            )?.sourceGroups
                          )}
                          </div>)}
                        </div>

                      </div>
                      {!!getExclusionByTenant(tenant.name).length && (
                        <div className="group-sync-tenant">
                          {
                            <button
                              className="excl-button"
                              onClick={() =>
                                setExclusionsShown((prev) => !prev)
                              }
                            >
                              <div className="excl-list-title">
                                <strong>Exclusions</strong>
                                {!exclusionsShown ? (
                                  <img src={arrowdown} alt="arrow down" />
                                ) : (
                                  <img
                                    style={{ transform: 'rotate(180deg)' }}
                                    src={arrowdown}
                                    alt="arrow up"
                                  />
                                )}
                              </div>
                            </button>
                          }
                        </div>
                      )}
                      {exclusionsShown && (
                        <ul className="excl-list">
                          {getUserNamesByEmail(
                            getExclusionByTenant(tenant.name)
                          )}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div style={{ display: 'flex', alignContent: 'center' }}>
                <span className="filter-link">
                  <button title='Filters' onClick={() => setFiltersShown((prev) => !prev)}>
                    <img src={filter} alt="filter" />
                  </button>
                </span>
                <NavLink
                  to={`/back_office/groups/${group}/edit`}
                  className="button-link"
                >
                  <button type="button">
                    <img src={edit} alt="edit"></img>
                    Edit
                  </button>
                </NavLink>
              </div>
            </div>

            {filtersShown && (
              <UsersFilters users={users} setFilteredUsers={setFilteredUsers} />
            )}

            <div className="audit table">
              {!!items.length ? (
                <table className="group-users-table">
                  <thead className="table-th">
                    <tr className="group-users-not-clickable group-users-tr">
                      <th>User ID</th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('email')}
                          className={getClassNamesFor('email', sortConfig)}
                        >
                          Email
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('firstName')}
                          className={getClassNamesFor('firstName', sortConfig)}
                        >
                          First name
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('secondName')}
                          className={getClassNamesFor('secondName', sortConfig)}
                        >
                          Last name
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('tenantName')}
                          className={getClassNamesFor('tenantName', sortConfig)}
                        >
                          Tenant
                        </button>
                      </th>
                      <th>Sync method</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {items.map((u) => (
                      <tr key={u.id} className={' group-users-tr '}>
                        <td className="group-td-email">{u.id}</td>
                        <td className="group-td-email">{u.email}</td>
                        <td>{u?.firstName}</td>
                        <td>{u?.secondName}</td>
                        <td className="group-td-email">{u.tenantName}</td>
                        <td className="list-td-email">
                          <span>
                            {u.syncType && (
                              <img
                                src={
                                  u.syncType === 'azure'
                                    ? azure
                                    : u.syncType === 'google'
                                      ? google
                                      : u.syncType === 'csv'
                                        ? csv
                                        : ''
                                }
                                alt="Logo"
                                className="list-td-email-icon"
                              ></img>
                            )}
                            {u.syncType}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-groups-message">
                  No users matching your filters
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
