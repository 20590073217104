import React from 'react';
import { TemplateProneTable } from './TemplateProneTable';

export const ProneTable = ({ tablesData }) => {
  return (
    <div className="prone-tables-grid">
      {tablesData?.map((table, index) => (
        <TemplateProneTable key={index} title={table.title} columns={table.columns} data={table.data} />
      ))}
    </div>
  );
};
