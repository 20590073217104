import React, { useContext, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import moment from 'moment';
import ReactSelect from '../ReactSelect/ReactSelect';
import { AllUsersOption, DateRangeOption, TenantOption } from '../ReactSelect/ReactSelectOption';
import { useHttp } from '../../hooks/http.hook';
import { AnalyticsGeneralContext } from '../../context/AnalyticsGeneralContext';
import { dateRangeOptions } from '../../config/config';
import { AuthContext } from '../../context/AuthContext';
import { useCrypto } from '../../hooks/crypto.hook';
import 'react-datepicker/dist/react-datepicker.css';

export const MailingAndActionsFilterBar = ({
  selectedTenant, setSelectedTenant, selectedUser, setSelectedUser,
  startDate, setStartDate, endDate, setEndDate, minDate, setMinDate,
  maxDate, setMaxDate, selectedDateRange, setSelectedDateRange,
  startDateParam, endDateParam, tenantId, userId, removeQueryParams,
  dateRangeOptionsList, setDateRangeOptionsList
}) => {
  const { request, secondaryLoading, loading } = useHttp();
  const { tenants: contextTenants, generalLoading } = useContext(AnalyticsGeneralContext);
  const { allUsers, token } = useContext(AuthContext);
  const { encryptData, decryptData } = useCrypto();

  const [tenants, setTenants] = useState([]);

  useMemo(async () => {
    try {
      if (token) {
        const data = encryptData({
          tenantId: selectedTenant?.value,
          userId: selectedUser?.value,
        });
        const fetched = await request('/back_office/api/analytics/min_date', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const decryptedFetchedData = decryptData(fetched);

        startDateParam && setStartDate(startDateParam);
        endDateParam && setEndDate(endDateParam);
        setMinDate(decryptedFetchedData?.minDate);
        setMaxDate(moment().format('YYYY-MM-DD'));

        return fetched;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant, selectedUser]);

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    setSelectedUser(null);
    setSelectedDateRange(dateRangeOptions[0]);
    removeQueryParams();
    setStartDate(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    setEndDate(moment().format('YYYY-MM-DD'));
  };

  const allUsersFilteredByTenant = allUsers.filter(user => selectedTenant
    ? user.tenantId === selectedTenant.id
    : user
  );

  const allUsersOptionList = allUsersFilteredByTenant?.map(user => (
    {
      value: user.id,
      label: user.email,
      caption: user.firstName + ' ' + user.secondName,
      tenantId: user.tenantId
    }
  ));

  const tenantsFilteredByUser = tenants.filter(tenant => selectedUser
    ? tenant.id === selectedUser.tenantId
    : tenant
  );

  useEffect(() => {
    setTenants(contextTenants);

    const minDateDiff = moment().diff(moment(minDate), 'days');
    const maxDateDiff = moment().diff(moment(maxDate), 'days');
    setDateRangeOptionsList(dateRangeOptions.filter(option => (option.value <= minDateDiff && option.value >= maxDateDiff) || option.value === 0));

    tenantId && setSelectedTenant(contextTenants.find(({ id }) => id === tenantId));
    userId && setSelectedUser(allUsersOptionList.find(({ value }) => String(value) === userId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextTenants, minDate, tenantId, userId]);

  const handleUserChange = (data) => {
    setSelectedUser(data);
    setSelectedDateRange(dateRangeOptions[0]);
    removeQueryParams();
    setStartDate(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    setEndDate(moment().format('YYYY-MM-DD'));
  };

  const handleDateRangeChange = (data) => {
    if (!data || data?.value === 0) {
      setStartDate(minDate);
      setEndDate(maxDate);
    } else {
      setStartDate(moment().subtract(data.value, 'days').format('YYYY-MM-DD'));
      setEndDate(moment().format('YYYY-MM-DD'));
    }
    setSelectedDateRange(data);
    removeQueryParams();
  };

  return (
    <div className='history-card history-card-mailing'>
      <div className="input-container-history-actions">
        <div className="input-container-history-actions-select">
          <div className={cn('input-container-analytics-item input-container-history-actions-item', {
            'input-container-analytics-item--disabled': loading || secondaryLoading || generalLoading
          })}>
            <div className='executive-input-field-analytics-tenant'>
              <label className={cn('userslist-select-label input-container-history-actions-item', {
                "input-edit-history-loading": loading || secondaryLoading || generalLoading
              })}>
                <ReactSelect
                  options={ tenantsFilteredByUser }
                  optionComponent={TenantOption}
                  placeholder={'Select Tenant...'}
                  classNames={'option-select-default'}
                  value={selectedTenant}
                  onChange={handleTenantChange}
                  isSearchable={true}
                  isClearable={true}
                  isDisabled={loading || secondaryLoading || generalLoading}
                  optionHeight={28}
                  rows={10}
                />
              </label>
            </div>
          </div>

          <div className={cn('all-users-select', {
            'input-container-analytics-item--disabled': loading || secondaryLoading || generalLoading
          })}>
            <label className={cn('userslist-select-label input-container-history-actions-item', {
              "input-edit-history-loading": loading || secondaryLoading || generalLoading
            })}>
              <ReactSelect
                classNames={'multiselect-user'}
                options={ allUsersOptionList }
                optionComponent={ AllUsersOption }
                placeholder={'Select User...'}
                onChange={handleUserChange}
                value={selectedUser}
                isSearchable={true}
                isClearable={true}
                isDisabled={loading || secondaryLoading || generalLoading}
                optionHeight={50}
                rows={6}
              />
            </label>
          </div>
        </div>

        <div className="input-container-history-actions-select">
          <p>Date range:</p>
          <div className='input-field-history-date-range-container' >
            <div className="input-field-history">
              <DatePicker
                selected={startDate ? moment(startDate).format() : null}
                onChange={(date) => {
                  setStartDate(moment(date).format('YYYY-MM-DD'));
                  setSelectedDateRange({ value: -1, label: 'Custom range' });
                  removeQueryParams();
                }}
                selectsStart
                onKeyDown={(e) => e.preventDefault()}
                minDate={minDate ? moment(minDate).format() : null}
                maxDate={maxDate ? moment(maxDate).format() : null}
                className={cn("input-edit-history", {
                  "input-edit-history-loading": loading || secondaryLoading || generalLoading
                })}
                disabled={loading || secondaryLoading || generalLoading}
                placeholderText="Enter Date"
              />
            </div>
            <p>&#11020;</p>
            <div className="input-field-history">
              <DatePicker
                selected={endDate ? moment(endDate).format() : null}
                onChange={(date) => {
                  setEndDate(moment(date).format('YYYY-MM-DD'));
                  setSelectedDateRange({ value: -1, label: 'Custom range' });
                  removeQueryParams();
                }}
                selectsStart
                onKeyDown={(e) => e.preventDefault()}
                minDate={minDate ? moment(minDate).format() : null}
                maxDate={maxDate ? moment(maxDate).format() : null}
                className={cn("input-edit-history", {
                  "input-edit-history-loading": loading || secondaryLoading || generalLoading
                })}
                disabled={loading || secondaryLoading || generalLoading}
                placeholderText="Enter Date"
              />
            </div>
          </div>

          <div className={'date-range-select'}>
            <label className={cn('date-range-select-label input-container-history-actions-item', {
                'input-edit-history-loading': loading || secondaryLoading || generalLoading,
                'date-range-select-label--enabled': !secondaryLoading,
              })}>
              <ReactSelect
                options={ dateRangeOptionsList }
                optionComponent={ DateRangeOption }
                placeholder={'Date Range...'}
                onChange={handleDateRangeChange}
                value={selectedDateRange}
                isSearchable={true}
                isDisabled={loading || secondaryLoading || generalLoading}
                optionHeight={28}
                rows={10}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  )
};
