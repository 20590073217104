/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import { useSortableData } from '../../hooks/sort.hook';
import { getClassNamesFor } from '../../common/getClassNamesFor';
import cross from '../../images/icons/Vector.svg';
import { useCrypto } from '../../hooks/crypto.hook';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import './NewUsersGroup.css';
import { CustomizedTooltipDisabled } from '../../components/Tooltips/Tooltip';
import { SyncModal } from '../../components/Modal/SyncModal.js';
import azure from '../../images/icons/Azure.svg';
import google from '../../images/icons/Google (colors).svg';
import csv from '../../images/icons/File Red.svg';

export const EditUserGroup = () => {
  const navigate = useNavigate();
  const { loading, request } = useHttp();
  const { token } = useAuth();
  const { decryptData, encryptData } = useCrypto();
  const auth = useContext(AuthContext);
  const { allUsers } = auth;
  const { group } = useParams();

  const [users, setUsers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [selectedUsersShown, setSelectedUsersShown] = useState(false);
  const [syncModal, setSyncModal] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [exclusions, setExclusions] = useState([]);

  const { items, requestSort, sortConfig } = useSortableData(users);
  const normalizedGroupName = group?.split('_').join(' ');

  const fetchAllTenants = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          '/back_office/api/user/get_all_tenants',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptTenants = decryptData(fetched).filter(t => t.type !== 'csv');

        setTenants(decryptTenants);

        return decryptTenants;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const linkedTenants = tenants.filter(
    (t) =>
      t.groupsMap?.find((g) => g.systemGroup === normalizedGroupName)
        ?.sourceGroups.length
  );

  const handleSelectRow = (u) => {
    const emails = linkedTenants?.map((t) =>
      t.groupsMap
        ?.find((g) => g.systemGroup === normalizedGroupName)
        ?.sourceGroups?.map((g) => g.members?.map((m) => m.mail))
    );
    if (emails.includes(u.email)) {
      setExclusions((prevState) =>
        prevState.includes(u.id)
          ? prevState.filter((id) => id !== u.id)
          : [...prevState, u.id]
      );
    }
    setSelectedRows((prevState) =>
      prevState.includes(u.id)
        ? prevState.filter((id) => id !== u.id)
        : [...prevState, u.id]
    );
  };

  const fetchGroupUsers = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          `/back_office/api/groups/${group}/users`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptUsers = decryptData(fetched);
        setSelectedRows(decryptUsers.map((u) => u.id));
        return decryptUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, group]);

  const submitHandler = async () => {
    try {
      const data = encryptData(selectedRows);
      const response = await request(
        `/back_office/api/groups/${group}`,
        'POST',
        { data },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      auth.showToastMessage(response.error, response.message);
      if (response.message) {
        if (selectedRows.length) {
          return navigate(`/back_office/groups/${group}`);
        }
      }
    } catch (error) { }
  };

  const deleteGroup = async () => {
    try {
      const response = await request(
        `/back_office/api/groups/delete/${group}`,
        'POST',
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      auth.showToastMessage(response.error, response.message);
      if (response.message) {
        return navigate(`/back_office/groups`);
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchGroupUsers();
  }, [fetchGroupUsers]);

  useEffect(() => {
    fetchAllTenants();
  }, [fetchAllTenants]);

  useEffect(() => {
    if (filterValue.length) {
      setUsers(() =>
        allUsers.filter(
          (u) =>
            String(u.id).includes(filterValue) ||
            u.email?.includes(filterValue?.toLowerCase()) ||
            [u.firstName?.toLowerCase(), u.secondName?.toLowerCase()]
              .join(' ')
              ?.includes(filterValue?.toLowerCase()) ||
            [u.secondName?.toLowerCase(), u.firstName?.toLowerCase()]
              .join(' ')
              ?.includes(filterValue?.toLowerCase()) ||
            u.firstName?.toLowerCase()?.includes(filterValue?.toLowerCase()) ||
            u.secondName?.toLowerCase()?.includes(filterValue?.toLowerCase())
        )
      );
    } else {
      setUsers(allUsers);
    }
  }, [filterValue, allUsers]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {!loading && (
        <div className="users-main">
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <NavLink
                className="groups-back-link"
                to={`/back_office/groups/${group}`}
              >
                Back to Group
              </NavLink>
              <button className="delete-link" onClick={deleteGroup}>
                Delete group
              </button>
            </div>

            <p className="new-group-users-table-add-title">Edit group</p>
            <h4 className="new-group-users-table-add-subtitle">
              Select users for the group
            </h4>
            <div className="input-container">
              <input
                id="users"
                name="users"
                placeholder="Search users..."
                value={filterValue}
                className="group-input-edit multidelect"
                onChange={(e) => setFilterValue(e.target.value)}
              />
              <div className="group-buttons-container">
                <div>
                  {!tenants.length ? (
                    <CustomizedTooltipDisabled
                      position={'top'}
                      text1={'Will be active after first sync with'}
                      text2={'Azure Active Directory or'}
                      text3={'Google Workspace'}
                      button={
                        <button
                          className="group-button-secondary"
                          disabled={!tenants.length}
                        >
                          Sync settings
                        </button>
                      }
                    />
                  ) : (
                    <button
                      className="group-button-secondary"
                      onClick={() => setSyncModal(true)}
                      disabled={!tenants.length}
                    >
                      Sync settings
                    </button>
                  )}
                </div>

                <button
                  className="group-button group-button-simple"
                  onClick={async () => { await submitHandler() }}
                  type="submit"
                  disabled={!selectedRows.length}
                >
                  Save changes
                </button>
              </div>
            </div>

            {!!selectedRows.length && (
              <>
                <div className="group-selected-bar">
                  <button
                    className={
                      selectedUsersShown
                        ? 'email-button-active'
                        : 'email-button'
                    }
                    onClick={() => setSelectedUsersShown((prev) => !prev)}
                  >
                    {selectedRows.length < 2
                      ? `${selectedRows.length} user selected`
                      : `${selectedRows.length} users selected`}
                  </button>
                  <button
                    className="secondary-button"
                    onClick={() => setSelectedRows([])}
                  >
                    Clear all
                  </button>
                </div>

                {!!selectedUsersShown && (
                  <div className="emails-container">
                    {selectedRows.map((selectedId) => (
                      <div className="email-container">
                        {allUsers.find((u) => u.id === selectedId)?.email}
                        <span className="emails-container-img">
                          <img
                            onClick={() =>
                              setSelectedRows((prev) =>
                                prev.filter((id) => id !== selectedId)
                              )
                            }
                            src={cross}
                            alt="delete"
                          ></img>
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            <div className="audit table">
              <table className="new-group-users-table-table">
                <thead className="table-th">
                  <tr className="new-group-users-table-not-clickable new-group-users-table-tr">
                    <th></th>
                    <th>User ID</th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('email')}
                        className={getClassNamesFor('email', sortConfig)}
                      >
                        Email
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('firstName')}
                        className={getClassNamesFor('firstName', sortConfig)}
                      >
                        First name
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('secondName')}
                        className={getClassNamesFor('secondName', sortConfig)}
                      >
                        Last name
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('tenantName')}
                        className={getClassNamesFor('tenantName', sortConfig)}
                      >
                        Tenant
                      </button>
                    </th>
                    <th>Sync method</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {items.map((u) => (
                    <tr
                      key={u.id}
                      onClick={() => handleSelectRow(u)}
                      className={'new-group-users-table-clickable-row new-group-users-table-tr '.concat(
                        selectedRows.includes(u.id) ? 'group-selected' : ''
                      )}
                    >
                      <td>
                        <div className="user-checkbox-action">
                          <label className="checkbox-label">
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={selectedRows.includes(u.id)}
                              onChange={() =>
                                setSelectedRows((prevState) =>
                                  prevState.includes(u.id)
                                    ? prevState.filter((id) => id !== u.id)
                                    : [...prevState, u.id]
                                )
                              }
                            />
                            <span className="checkbox-span"></span>
                          </label>
                        </div>
                      </td>
                      <td className="group-td-email">{u.id}</td>
                      <td className="group-td-email">{u.email}</td>
                      <td>{u?.firstName}</td>
                      <td>{u?.secondName}</td>
                      <td className="group-td-email">{u.tenantName}</td>
                      <td className="list-td-email">
                        <span>
                          {u.syncType && (
                            <img
                              src={
                                u.syncType === 'azure'
                                  ? azure
                                  : u.syncType === 'google'
                                    ? google
                                    : u.syncType === 'csv'
                                      ? csv
                                      : ''
                              }
                              alt="Logo"
                              className="list-td-email-icon"
                            ></img>
                          )}
                          {u.syncType}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {!!tenants.length && <SyncModal
        group={group}
        setSyncModal={setSyncModal}
        syncModal={syncModal}
      />}
    </>
  );
};
