import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import { useCrypto } from '../../hooks/crypto.hook';
import Select, { components } from 'react-select';
import edit from '../.././images/icons/Pen.svg';
import arrowdown from '../.././images/icons/caret-down.svg';
import cn from 'classnames';
import cross from '../../images/icons/Vector.svg';
import colourStyles from '../../styles/colour-style';
import './SyncGroups.css';
import check from '../../images/icons/Check Green.svg';
import cancel from '../../images/icons/Cross Red.svg';
import { languageOptions, groupsOnPage } from '../../config/config';
import { NavLink } from 'react-router-dom';
import { useSortableData } from '../../hooks/sort.hook';
import settings from '../.././images/icons/gear.svg';
import { getClassNamesFor } from '../../common/getClassNamesFor';

export const SyncGroups = ({ syncType }) => {
  const { request } = useHttp();
  const { showToastMessage, groupsLogs } = useContext(AuthContext);
  const { decryptData, encryptData } = useCrypto();
  const { token } = useAuth();

  const [selectedRows, setSelectedRows] = useState([]);
  const [groupsShown, setGroupsShown] = useState(false);
  const [lang, setLang] = useState();
  const [editLangModeForId, setEditLangModeForId] = useState(false);
  const [selectedUsersShown, setSelectedUsersShown] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedTenant, setSelectedTenant] = useState({
    value: 'all',
    label: 'All',
    id: 0,
  });
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: 'all',
    label: 'All',
    id: 0,
  });
  const [selectedSystemGroup, setSelectedSystemGroup] = useState({
    value: 'all',
    label: 'All',
    id: 0,
  });
  const [searchValue, setSearchValue] = useState('');

  const fetchAllTenants = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          '/back_office/api/user/get_all_tenants',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        const decryptTenants = decryptData(fetched);
        const filteredTenants = decryptTenants.filter(t => t.type === syncType)
        setTenants(filteredTenants);

        return filteredTenants;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const changeLanguageMapping = async ({ groups, language, syncType }) => {
    const selectedData = {
      groups,
      language,
      syncType,
    };

    if (token) {
      const data = encryptData(selectedData);
      const response = await request(
        `/back_office/api/groups/change/language_map`,
        'POST',
        { data },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      fetchAllTenants();
      setSelectedRows([]);
      showToastMessage(response.error, response.message);
    }
  };

  const languageList = languageOptions.map((v, i) => {
    return { value: v?.toLowerCase(), label: v, id: i, key: i, type: 'language' };
  });

  const languageOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`actionTypes-label-${value.toString().split(' ').join('-')}`}>{label}</div>
      </components.Option>
    );
  };

  const getGroupsOption = () => {
    const groupsMap = tenants.map((t) => t.groupsMap).flat();

    const groupsNames = groupsMap
      .filter((e) => e.sourceGroups?.length)
      .map((e) => e.systemGroup);

    const uniqueGroupsNamesSet = new Set(groupsNames);
    const uniqueGroupsNames = [...uniqueGroupsNamesSet];

    return uniqueGroupsNames.map((v, i) => {
      return {
        value: v?.toLowerCase(),
        label: v,
        id: i,
        key: i,
        type: 'group'
      };
    });
  };

  const handleLangChange = (data) => {
    setLang(data);
  };

  const getLanguageName = (tenant, selectedId) => {
    const selectedTenant = tenants.find(
      (e) => e.name === tenant.tenantName
    );

    const languageName = selectedTenant?.languageMap?.find((e) =>
      e?.values?.includes(selectedId)
    )?.name;

    return languageName;
  };

  const clearFilters = () => {
    setSelectedLanguage({ value: 'all', label: 'All', id: 0 });
    setSelectedSystemGroup({ value: 'all', label: 'All', id: 0 });
    setSelectedTenant({ value: 'all', label: 'All', id: 0 });
    setSearchValue('');
  }

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    setSelectedRows([]);
  };

  const handleLanguageChange = (data) => {
    setSelectedLanguage(data);
    setSelectedRows([]);
  };

  const handleGroupChange = (data) => {
    setSelectedSystemGroup(data);
    setSelectedRows([]);
  };

  const groups = groupsLogs?.[syncType]
    ?.map((tenant) =>
      tenant.userGroups.map((group) => {
        return {
          ...group,
          tenant: tenant,
          tenantName: tenant.tenantName,
          language: getLanguageName(tenant, group.id) || 'English',
        };
      })
    )
    .flat() || [];

  const { items, requestSort, sortConfig } = useSortableData(groups);
  const selectedGroupsIds = selectedRows.map((e) => e.groupsId).flat();
  const tenantList = tenants.map((v, i) => {
    return {
      value: v.name.toLowerCase(),
      label: v.name.trim(),
      id: i,
      type: 'tenant',
    };
  });

  const Option = (props) => {
    const { label, value, caption, type } = props.data;

    return (
      <components.Option {...props}>
        <div
          className={cn(
            `syncTypes-label-${value.toString().split(' ').join('-')}`,
            `actions-label-${value.toString().split(' ').join('-')}`,
            `sync-groups-select-icon-${type}`,
            'sync-groups-select-icon'
          )}
        >
          {label}
        </div>
        <div className="caption">{caption}</div>
      </components.Option>
    );
  };

  const handleMapChange = async (groups, language, syncType) => {
    await changeLanguageMapping({
      groups,
      language,
      syncType,
    });
    setEditLangModeForId(false);
    setLang('');
  };

  const removeGroup = (tenant, selectedId) => {
    setSelectedRows((prev) => {
      const tenant = prev.find((e) => e.groupsId.includes(selectedId));
      const groupsId = tenant.groupsId.filter((e) => e !== selectedId);

      return [
        ...prev.filter((e) => e.tenantName !== tenant.tenantName),
        { tenantName: tenant.tenantName, groupsId },
      ];
    });
  };

  const addGroup = (tenant, selectedId) => {
    const existedTenant = selectedRows.find(
      (e) => e.tenantName === tenant.tenantName
    );
    if (existedTenant) {
      setSelectedRows((prevState) => [
        ...prevState.filter((e) => e.tenantName !== tenant.tenantName),
        {
          groupsId: [...existedTenant.groupsId, selectedId],
          tenantName: tenant.tenantName,
        },
      ]);
    } else {
      setSelectedRows((prevState) => [
        ...prevState,
        {
          groupsId: [selectedId],
          tenantName: tenant.tenantName,
        },
      ]);
    }

    if (selectedRows.length) {
      setEditLangModeForId(false);
    }
  };

  const getSystemGroupLabels = (tenant, selectedId) => {
    const selectedTenant = tenants.find(
      (e) => e.name === tenant.tenantName // tenantName changed to name
    );

    const systemGroups = selectedTenant?.groupsMap?.filter((e) =>
      e?.sourceGroups?.includes(selectedId)
    );

    const names = systemGroups?.map((group) => group.systemGroup);

    return names;
  };

  const getSystemGroupName = (tenant, selectedId) => {
    const systemGroups = getSystemGroupLabels(tenant, selectedId);
    const names = systemGroups?.map((group, i) => (
      <span className="group-link">
        <NavLink to={`/back_office/groups/${group.split(' ').join('_')}`}>
          {group}
          {i !== systemGroups.length - 1 && ', '}
        </NavLink>
      </span>
    ));

    return names?.length ? names : 'No mapped groups';
  };

  const getLanguageDefaultValue = (tenant, selectedId) => {
    const languageName = getLanguageName(tenant, selectedId);
    return languageList.find((e) => e.label === languageName);
  };

  const filterGroups = (items) =>
    items
      .filter(
        (group) =>
          (selectedTenant?.value !== 'all'
            ? group.tenantName === selectedTenant?.label
            : true) &&
          (selectedLanguage?.value !== 'all'
            ? group?.language === selectedLanguage?.label
            : true) &&
          (selectedSystemGroup?.value !== 'all'
            ? getSystemGroupLabels(group.tenant, group.id).includes(
              selectedSystemGroup?.label
            )
            : true) &&
          (searchValue !== ''
            ? group.name.toLowerCase().includes(searchValue.toLowerCase())
            : true)
      )
  // .sort((a, b) => b.members.length - a.members.length);

  let filteredGroups = filterGroups(items);

  useEffect(() => {
    fetchAllTenants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAllTenants]);

  return (
    <>
      {!!groups?.length && (
        <div className="groups-card">
          <div className="groups-table-title">
            <div className="groups-table-title">
              <h3>Groups</h3>
              <div className="log-groups-count">{groups?.length}</div>
            </div>
            <button
              className="sync-groups-arrow-button"
              onClick={() => setGroupsShown((prev) => !prev)}
            >
              {!groupsShown ? (
                <img src={arrowdown} alt="arrow down" />
              ) : (
                <img
                  style={{ transform: 'rotate(180deg)' }}
                  src={arrowdown}
                  alt="arrow up"
                />
              )}
            </button>
          </div>
          {groupsShown && (
            <div>
              <div className="sync-groups-filters">
                <div className="sync-group-select userslist-select-tenant">
                  <label className="userslist-select-label">
                    Tenant
                    <Select
                      id="select"
                      className="sync-groups-select"
                      options={[
                        {
                          value: 'all',
                          label: 'All',
                          id: 0,
                        },
                        ...tenantList,
                      ]}
                      components={{ Option }}
                      placeholder="Select tenant"
                      value={selectedTenant}
                      onChange={handleTenantChange}
                      isSearchable={true}
                      styles={colourStyles}
                    />
                  </label>
                </div>
                <div className=" sync-group-select userslist-select-language">
                  <label className="userslist-select-label">
                    Language
                    <Select
                      id="select"
                      className="sync-groups-select"
                      options={[
                        {
                          value: 'all',
                          label: 'All',
                          id: 0,
                        },
                        ...languageList,
                      ]}
                      components={{ Option }}
                      placeholder="Select language"
                      value={selectedLanguage}
                      onChange={handleLanguageChange}
                      isSearchable={true}
                      styles={colourStyles}
                    />
                  </label>
                </div>
                <div className="sync-group-select userslist-select-groups">
                  <label className="userslist-select-label">
                    System Group
                    <Select
                      id="select"
                      className="sync-groups-select"
                      options={[
                        {
                          value: 'all',
                          label: 'All',
                          id: 0,
                        },
                        ...getGroupsOption(),
                      ]}
                      components={{ Option }}
                      placeholder="Select group"
                      value={selectedSystemGroup}
                      onChange={handleGroupChange}
                      isSearchable={true}
                      styles={colourStyles}
                    />
                  </label>
                </div>
              </div>
              <div className="input-search-groups">
                  <label className="userslist-select-label">
                    Search Group
                  </label>
                  <input
                    placeholder="Type to search"
                    value={searchValue}
                    type='text'
                    onChange={(e) => {
                      setSearchValue(e.target.value)
                      setPage(1);
                    }
                  }
                  />
                  <i
                    role='button'
                    onClick={() => setSearchValue('')}
                  className={cn({ 'tenants-clear-disabled': !searchValue.length })}
                  />
                </div>
              {filteredGroups.length ? (
                <div>
                  <div
                    className={cn('all-lang-container', {
                      'sync-groups-container-empty':
                        !selectedRows?.length || !selectedUsersShown,
                    })}
                  >
                    <div className="all-languages-selected-bar">
                      <button
                        className={
                          selectedUsersShown
                            ? 'all-users-email-button-active'
                            : 'all-users-email-button'
                        }
                        onClick={() => setSelectedUsersShown((prev) => !prev)}
                      >
                        {selectedGroupsIds.length < 2
                          ? `${selectedGroupsIds.length} group selected`
                          : `${selectedGroupsIds.length} groups selected`}
                      </button>
                      {!!selectedRows.length && (
                        <button
                          className="all-users-secondary-button"
                          onClick={() => setSelectedRows([])}
                        >
                          Clear all
                        </button>
                      )}
                    </div>
                    {!!selectedUsersShown && (
                      <div className="all-users-emails-container">
                        {selectedRows.map((tenant) =>
                          tenant.groupsId.map((selectedId) => (
                            <div
                              className="all-users-email-container"
                              key={selectedId}
                            >
                              {groups.find((u) => u.id === selectedId)?.name}
                              <span className="all-users-email-container-img">
                                <img
                                  onClick={() =>
                                    removeGroup(tenant, selectedId)
                                  }
                                  src={cross}
                                  alt="delete"
                                ></img>
                              </span>
                            </div>
                          ))
                        )}
                      </div>
                    )}
                    {!!selectedGroupsIds.length ? (
                      <div className="lang-select-container">
                        <Select
                          id="select"
                          className="lang-select"
                          options={languageList}
                          components={{ languageOption }}
                          placeholder="Select language"
                          value={lang}
                          onChange={handleLangChange}
                          isSearchable={true}
                          styles={colourStyles}
                        />
                        <button
                          className="submit-button"
                          onClick={() => {
                            handleMapChange(selectedRows, lang.label, syncType);
                          }}
                          disabled={!selectedRows.length || !lang}
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    ) : (
                      <span className="group-link">
                        <NavLink to={`/back_office/groups/mapping`}>
                          Go to group mapping
                        </NavLink>
                      </span>
                    )}
                  </div>
                  <table className="logs-table">
                    <thead className="table-th-logs">
                      <tr
                        style={{ width: '100%' }}
                        className="not-clickable-groups tr-grid-groups tr-groups"
                      >
                        <th style={{ width: '5%' }}>
                          <div className="user-checkbox-action">
                            <label className="checkbox-label">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={filteredGroups.length === selectedGroupsIds.length}
                                onChange={
                                  selectedGroupsIds.length
                                    ? () => setSelectedRows([])
                                    : () =>
                                      setSelectedRows(
                                        groupsLogs?.[syncType].map((t) => ({
                                          groupsId: t.userGroups
                                            .map((e) => e.id)
                                            .filter((e) =>
                                              filteredGroups?.find(
                                                (g) => g.id === e
                                              )
                                            ),
                                          tenantName: t.tenantName,
                                        }))
                                      )
                                }
                              />
                              <span className="checkbox-span"></span>
                            </label>
                          </div>
                        </th>
                        <th style={{ width: '20%' }}>
                          <button
                            type="button"
                            onClick={() => requestSort('name')}
                            className={getClassNamesFor('name', sortConfig)}
                          >
                            Sync group name
                          </button>
                        </th>
                        {/* <th style={{ width: '10%' }}>
                          Users count
                        </th> */}
                        <th style={{ width: '20%' }}>
                          <button
                            type="button"
                            onClick={() => requestSort('tenantName')}
                            className={getClassNamesFor(
                              'tenantName',
                              sortConfig
                            )}
                          >
                            Tenant name
                          </button>
                        </th>
                        <th style={{ width: '20%' }}>
                          <button
                            type="button"
                            onClick={() => requestSort('language')}
                            className={getClassNamesFor('language', sortConfig)}
                          >
                            Language
                          </button>
                        </th>
                        <th style={{ width: '20%' }}>System group(s)</th>
                      </tr>
                    </thead>
                    <tbody className="table-body-logs">
                      {filteredGroups.map((group, i) => {
                        if (i < page * groupsOnPage) {
                          return (
                            <tr
                              key={group.id}
                              className={cn(
                                'tr-grid-groups clickable-row-logs tr-groups-csv'
                              )}
                            >
                              <td>
                                <div className="user-checkbox-action">
                                  <label className="checkbox-label">
                                    <input
                                      type="checkbox"
                                      className="checkbox"
                                      checked={selectedGroupsIds?.includes(
                                        group.id
                                      )}
                                      onChange={() => {
                                        selectedGroupsIds?.includes(group.id)
                                          ? removeGroup(group.tenant, group.id)
                                          : addGroup(group.tenant, group.id);

                                        if (selectedRows.length) {
                                          setEditLangModeForId(false);
                                        }
                                      }}
                                    />
                                    <span className="checkbox-span"></span>
                                  </label>
                                </div>
                              </td>
                              <td>
                              {group.name}
                              <p className="secondary-info">{`ID: ${group.id}`}</p>
                              </td>
                              {/* <td style={{ textAlign: 'center' }}>
                                {group.members.length}
                              </td> */}
                              <td>{group.tenant.tenantName}</td>
                              <td>
                                <div
                                  className={
                                    editLangModeForId === group.id &&
                                      !selectedRows.length
                                      ? 'icon-button-container'
                                      : 'table-group-button-container groups-log-details'
                                  }
                                >
                                  {editLangModeForId === group.id &&
                                    !selectedRows.length ? (
                                    <Select
                                      id="select"
                                      className="lang-select lang-select-in-row"
                                      options={languageList}
                                      components={{ languageOption }}
                                      placeholder="Select language"
                                      value={lang}
                                      defaultValue={getLanguageDefaultValue(
                                        group.tenant,
                                        group.id
                                      )}
                                      onChange={handleLangChange}
                                      isSearchable={true}
                                      styles={colourStyles}
                                    />
                                  ) : (
                                    getLanguageName(group.tenant, group.id) ||
                                    group.tenant.language ||
                                    'English'
                                  )}

                                  {editLangModeForId === group.id &&
                                    !selectedRows.length ? (
                                    <>
                                      <button
                                        type="button"
                                        className="groups-table-option"
                                        onClick={() => {
                                          handleMapChange(
                                            [
                                              {
                                                tenantName:
                                                  group.tenant.tenantName,
                                                groupsId: [group.id],
                                              },
                                            ],
                                            lang.label,
                                            syncType
                                          );
                                        }}
                                        disabled={!lang}
                                      >
                                        <img src={check} alt="edit"></img>
                                      </button>
                                      <button
                                        type="button"
                                        className="groups-table-option"
                                        onClick={() => {
                                          setEditLangModeForId(false);
                                        }}
                                      >
                                        <img src={cancel} alt="edit"></img>
                                      </button>
                                    </>
                                  ) : !selectedRows.length ? (
                                    <button
                                      type="button"
                                      className="groups-table-option"
                                      onClick={() => {
                                        setEditLangModeForId(group.id);
                                      }}
                                    >
                                      <img src={edit} alt="edit"></img>
                                    </button>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="icon-button-container">
                                  <div className="group-lang-buttons-container">
                                    {getSystemGroupName(group.tenant, group.id)}
                                  </div>
                                  <NavLink
                                    to={'/back_office/groups/mapping'}
                                    state={{ tenantName: group.tenant.tenantName, syncGroupId: group.id }}
                                    className="mapping-link"
                                  >
                                    <button
                                      type="button"
                                      className="groups-table-option"
                                    >
                                      <img src={settings} alt="edit"></img>
                                    </button>
                                  </NavLink>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                        return null;
                      })}
                    </tbody>
                  </table>

                  {page * groupsOnPage < filteredGroups.length && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <button
                        className="list-button"
                        onClick={() => {
                          setPage((prev) => prev + 1);
                        }}
                        // disabled={loading}
                        type="submit"
                      >
                        Download more
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="no-groups-message">
                  No groups matching your filters
                  <span className="group-link">
                    <button onClick={clearFilters}>Clear filters</button>
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
