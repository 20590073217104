import React, { useState, useCallback, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import cn from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';

import { AllUsersOption } from '../ReactSelect/ReactSelectOption';
import { UserActivity } from './UserActivity/UserActivity';
import { AuthContext } from '../../context/AuthContext';
import ReactSelect from '../ReactSelect/ReactSelect';
import { useCrypto } from '../../hooks/crypto.hook';
import { useHttp } from '../../hooks/http.hook';
import { Loader } from '../Loader/Loader';
import { CustomizedTooltipInfo } from '../Tooltips/Tooltip';

export const UserReportCard = () => {
  const { request, loading } = useHttp();
  const { token } = useContext(AuthContext);
  const { encryptData, decryptData } = useCrypto();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [usersOptionList, setUsersOptionList] = useState([]);
  const [userData, setUserData] = useState(null);
  const [found, setFound] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  const fetchAllUsers = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(`/back_office/api/analytics/all_users`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptUsers = decryptData(fetched);

        const optionList = decryptUsers.map(user => ({
          value: user.id,
          label: user.email,
          caption: user.firstName + ' ' + user.secondName,
          minMailingDate: user.minMailingDate,
          maxMailingDate: user.maxActionsDate || user.maxMailingDate,
        }));

        setUsersOptionList(optionList);

        return decryptUsers;
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  useEffect(() => {
    if (!selectedOptions) setFound(false);
  }, [selectedOptions]);

  const fetchUserData = useCallback(async () => {
    try {
      if (token && selectedOptions?.value) {
        const data = encryptData({
          userId: selectedOptions?.value,
          startDate: startDate || minDate,
          endDate: endDate || maxDate,
        })
        const responseUserData = await request('/back_office/api/analytics/user_analytic', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const decryptUserData = decryptData(responseUserData);

        if (decryptUserData) {
          setUserData(decryptUserData);
          setFound(true);
        }

        return decryptUserData;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedOptions?.value, startDate, endDate]);


  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleUserSelect = (data) => {
    const minMailingDate = data?.minMailingDate || '2000-01-01';
    const maxMailingDate = data?.maxMailingDate || moment().format('YYYY-MM-DD');

    const minMailingDateDiff = moment().diff(minMailingDate, 'days')
    if (minMailingDateDiff > 365) setStartDate(moment().subtract(1, 'year').format('YYYY-MM-DD'));
    else setStartDate(minMailingDate);

    setSelectedOptions(data);
    setMinDate(minMailingDate);
    setEndDate(maxMailingDate);
    setMaxDate(maxMailingDate);
  };

  return (
    <>
      <div className={cn('user-analytics-card', {'user-analytics-card--loading': loading})}>
        <div className='user-report-title'>
          <h3>Select User to populate User Report Card</h3>
        </div>
        <div className='analytics-input-container'>
          <div className='user-report-input'>
            <ReactSelect
              options={usersOptionList}
              optionComponent={AllUsersOption}
              classNames={cn('user-select', {'user-select--disabled': loading})}
              placeholder={'Select User...'}
              value={selectedOptions}
              onChange={handleUserSelect}
              isSearchable={true}
              isClearable={true}
              isDisabled={loading}
              optionHeight={50}
              rows={6}
            />
          </div>

          {selectedOptions?.value &&
            <div className='analytics-date-range-container'>
              <p>Select date range:</p>
              <div className='input-field-history-date-range-container' >
                <div className="input-field-history">
                  <DatePicker
                    selected={startDate ? moment(startDate).format() : null}
                    onChange={(date) => {
                      setStartDate(moment(date).format('YYYY-MM-DD'));
                    }}
                    selectsStart
                    onKeyDown={(e) => e.preventDefault()}
                    minDate={minDate ? moment(minDate).format() : null}
                    maxDate={endDate ? moment(endDate).format() : null}
                    className={cn("input-edit-history", {"input-edit-history-loading": loading})}
                    disabled={loading}
                    placeholderText="Enter Date"
                  />
                </div>
                <p>&#11020;</p>
                <div className="input-field-history">
                  <DatePicker
                    selected={endDate ? moment(endDate).format() : null}
                    onChange={(date) => {
                      setEndDate(moment(date).format('YYYY-MM-DD'));
                    }}
                    selectsStart
                    onKeyDown={(e) => e.preventDefault()}
                    minDate={startDate ? moment(startDate).format() : null}
                    maxDate={maxDate ? moment(maxDate).format() : null}
                    className={cn("input-edit-history", {"input-edit-history-loading": loading})}
                    disabled={loading || !selectedOptions?.value}
                    placeholderText="Enter Date"
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      {!loading
        ? userData && found
          ? <>
              <div className='user-analytics-raw'>
                <div className='user-report-mini-card'>
                  <div className='user-analytics-text-item-first'>
                    <div className='user-report-text-item-title-first'>User name:</div>
                    <div className='user-report-text-item-value-first'>{userData.firstName} {userData.secondName}</div>
                  </div>
                  <div className='user-analytics-text-item-first'>
                    <div className='user-report-text-item-title-first'>Email:</div>
                    <div className='user-report-text-item-value-second'>{userData.email}</div>
                  </div>
                  {userData.department && <div className='user-analytics-text-item-first'>
                    <div className='user-report-text-item-title-first'>Department:</div>
                    <div className='user-report-text-item-value-second'>{userData.department}</div>
                  </div>}
                  {userData.location && <div className='user-analytics-text-item-first'>
                    <div className='user-report-text-item-title-first'>Location:</div>
                    <div className='user-report-text-item-value-second'>{userData.location}</div>
                  </div>}
                  <div className='user-analytics-text-item-first'>
                    <div className='user-report-text-item-title-first'>Created At:</div>
                    <div className='user-report-text-item-value-second'>{new Date(userData.createdAt).toDateString()}</div>
                  </div>
                  <div className='user-analytics-text-item-first'>
                    <div className='user-report-text-item-title-first'>Risk Profile:</div>
                    <div className={`user-report-text-item-value-second ${userData?.["Risk_Profile"]?.replace(/ /g, '_') || "Low_Risk" }`}>{userData?.Risk_Profile || 'Low Risk'}</div>
                  </div>
                </div>
                <div className='user-report-mini-card'>
                  <div className='user-report-mini-card-title'>
                    <h3>Base metrics</h3>
                  </div>
                  <div className='user-analytics-text-item'>
                    <div className='user-report-text-item-title'>Educational Emails:</div>
                    <div className='education-text-item-value'>{parseInt(userData?.Total_Educational_Emails_Sent)?.toLocaleString('en-US')}</div>
                  </div>
                  <div className='user-analytics-text-item'>
                    <div className='user-report-text-item-title'>Educational Interactions:</div>
                    <div className='education-text-item-value'>{parseInt(userData?.Educational_Interactions)?.toLocaleString('en-US')}</div>
                  </div>
                  <div className='user-analytics-text-item'>
                    <div className='user-report-text-item-title education-text-item-title-info'>
                      Engagement Rate:
                      <CustomizedTooltipInfo
                        text={'Engagement Rate is calculated only for full completed months.'}
                      />
                    </div>
                    <div className='education-text-item-value'>{userData?.Engagement_Rate !== null ? `${userData?.Engagement_Rate?.toString()?.replace('.00', '')}%` : '-'}</div>
                  </div>
                </div>
                <div className='user-report-mini-card'>
                  <div className='user-report-mini-card-title'>
                    <h3>Phishing metrics</h3>
                  </div>
                  <div className='user-analytics-text-item'>
                    <div className='user-report-text-item-title'>Phishing Simulations:</div>
                    <div className='phishing-text-item-value'>{parseInt(userData?.Total_Phishing_Emails_Sent)?.toLocaleString('en-US')}</div>
                  </div>
                  <div className='user-analytics-text-item'>
                    <div className='user-report-text-item-title'>Phish Click Count:</div>
                    <div className='phishing-text-item-value'>{parseInt(userData?.Phishing_Click_Count)?.toLocaleString('en-US')}</div>
                  </div>
                  <div className='user-analytics-text-item'>
                    <div className='user-report-text-item-title'>Phish Click Rate:</div>
                    <div className='phishing-text-item-value'>{userData.Phish_Click_Rate}%</div>
                  </div>
                  <br/>

                  <div className='user-analytics-text-item'>
                    <div className='user-report-text-item-title'>Reported Phish Count:</div>
                    <div className='phishing-text-item-value'>{parseInt(userData?.Reported_Phish_Count)?.toLocaleString('en-US')}</div>
                  </div>

                  <div className='user-analytics-text-item'>
                    <div className='user-report-text-item-title'>Accurately Reported Phish Count:</div>
                    <div className='phishing-text-item-value'>{userData.Accurately_Reported_Phish_Count}</div>
                  </div>
                  <div className='user-analytics-text-item'>
                    <div className='user-report-text-item-title'>Inaccurately Reported Phish Count:</div>
                    <div className='phishing-text-item-value'>{userData.Inaccurately_Reported_Phish || 0}</div>
                  </div>

                  <div className='user-analytics-text-item'>
                    <div className='user-report-text-item-title'>Accuracy Rate:</div>
                    <div className='phishing-text-item-value'>{userData?.Accuracy_Rate || 0}%</div>
                  </div>
                  <br />

                  <div className='user-analytics-text-item'>
                    <div className='user-report-text-item-title'>Reported Simulated Phish Count:</div>
                    <div className='phishing-text-item-value'>{parseInt(userData?.PF_Reported_Emails)?.toLocaleString('en-US') || 0}</div>
                  </div>
                  <div className='user-analytics-text-item'>
                    <div className='user-report-text-item-title'>Simulated Phish Report Rate:</div>
                    <div className='phishing-text-item-value'>{userData?.PF_Accuracy_Rate || 0}%</div>
                  </div>
                  <br />

                </div>
              </div>

              <UserActivity
                userId={selectedOptions?.value}
                startDate={startDate}
                endDate={endDate}
              />
            </>
          : found &&
            <div className='user-analytics-card analytics-description'>
              <p>No search result found.</p>
            </div>
        : selectedOptions?.length
            ? <Loader />
            : null
      }
    </>
  );
};
