import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { useHttp } from '../../../hooks/http.hook';
import { NavLink } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import cn from 'classnames';
import './UsersDatabase.css';
import { AuthContext } from '../../../context/AuthContext';
import { CustomizedTooltipDisabled } from '../../../components/Tooltips/Tooltip';
import { useTitle } from '../../../hooks/title.hook';
import { useCrypto } from "../../../hooks/crypto.hook";

export const UsersDatabase = () => {
  useTitle("PhishFirewall | User Database");

  const { loading, request } = useHttp();
  const { user, token } = useContext(AuthContext);
  const { decryptData } = useCrypto();

  const [tenants, setTenants] = useState([]);

  const fetchAllTenants = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          `/back_office/api/user/get_all_tenants`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        const decryptUsers = decryptData(fetched);

        setTenants(decryptUsers);

        return decryptUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    fetchAllTenants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAllTenants]);
  if (loading) {
    return <Loader />
  }
  return (
    <> {!loading &&
      <div className="us-database-main">
        <h2 className="us-database-main-title">
          User Database
        </h2>

        <div className="us-database">
          <nav className="us-database-nav">
            <NavLink
              to="/back_office/user/users_database/all_users"
              className={({ isActive }) => cn('us-database-item', {
                'us-database-item--active': isActive,
              })}
            >
              <span>All Users</span>
            </NavLink>

            <NavLink
              to="/back_office/user/users_database/all_tenants"
              className={({ isActive }) => cn('us-database-item', {
                'us-database-item--active': isActive,
              })}
            >
              <span>All Tenants</span>
            </NavLink>

            <div>
              {(!user?.syncAD || (!tenants.find(e => user?.tenants?.tenantsIds.includes(e.id) && e.type === 'azure') && user?.role !== 'owner' && !user.tenants.allTenantsAccess)) &&
                <CustomizedTooltipDisabled
                  position={'top'}
                  text1={'Will be active after'}
                  text2={'first sync with'}
                  text3={'Azure Active Directory'}
                // text1={'This functionality'}
                // text2={'is not available yet'}
                />
              }
              <NavLink
                to="/back_office/user/users_database/ad"
                className={({ isActive }) => cn('us-database-item', {
                  'us-database-item--active': isActive,
                  'us-database-item--active-checked': isActive && user.syncAD,
                  'us-database-item--inactive-checked': !isActive && user.syncAD,
                  'item--disabled': !user?.syncAD || (!tenants.find(e => user?.tenants?.tenantsIds.includes(e.id) && e.type === 'azure') && user?.role !== 'owner' && !user.tenants.allTenantsAccess)
                })}
              >
                <span>Azure Active Directory</span>
              </NavLink>
            </div>

            <div>
              {(!user?.syncGoogle || (!tenants.find(e => user?.tenants?.tenantsIds.includes(e.id) && e.type === 'google') && user?.role !== 'owner' && !user.tenants.allTenantsAccess)) &&
                <CustomizedTooltipDisabled
                  position={'top'}
                  text1={'Will be active after'}
                  text2={'first sync with'}
                  text3={'Google Business Directory'}
                />
              }
              <NavLink
                to="/back_office/user/users_database/google"
                className={({ isActive }) => cn('us-database-item', {
                  'us-database-item--active': isActive,
                  'us-database-item--active-checked': isActive && user.syncGoogle,
                  'us-database-item--inactive-checked': !isActive && user.syncGoogle,
                  'item--disabled': !user?.syncGoogle || (!tenants.find(e => user?.tenants?.tenantsIds.includes(e.id) && e.type === 'google') && user?.role !== 'owner' && !user.tenants.allTenantsAccess)
                })}
              >
                <span>Google Business Directory</span>
              </NavLink>
            </div>
            <div>
              {(!user?.syncCSV || (!tenants.find(e => user?.tenants?.tenantsIds.includes(e.id) && e.type === 'csv') && user?.role !== 'owner' && !user.tenants.allTenantsAccess)) &&
                <CustomizedTooltipDisabled
                  position={'top'}
                  text1={'Will be active after'}
                  text2={'first sync from'}
                  text3={'CSV file'}
                // text1={'This functionality'}
                // text2={'is not available yet'}
                />
              }
              <NavLink
                to="/back_office/user/users_database/csv"
                className={({ isActive }) => cn('us-database-item', {
                  'us-database-item--active': isActive,
                  'us-database-item--active-checked': isActive && user.syncCSV,
                  'us-database-item--inactive-checked': !isActive && user.syncCSV,
                  'item--disabled': !user?.syncCSV || (!tenants.find(e => user?.tenants?.tenantsIds.includes(e.id) && e.type === 'csv') && user?.role !== 'owner' && !user.tenants.allTenantsAccess)
                })}
              >
                <span>CSV Sync</span>
              </NavLink>
            </div>
          </nav>

          {!user?.tenants?.syncAccess && user?.role !== 'owner' ?
            <CustomizedTooltipDisabled
              position={'left'}
              text1={'The Owner did not'}
              text2={'give you permission'}
              text3={'to set up a synchronization'}
              button={<NavLink
                to="/back_office/user/sync"
                className="us-database-sync-option"

              >
                <button disabled={true}>Choose other sync option</button>
              </NavLink>
              }
            />
            : <NavLink
              to="/back_office/user/sync"
              className="us-database-sync-option"
            >
              <button >Choose other sync option</button>
            </NavLink>
          }
        </div>

        <Outlet />
      </div>
    }
    </>
  )
}
