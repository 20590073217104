import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import cn from "classnames";
import { useHttp } from '../../hooks/http.hook';
import { Loader } from '../../components/Loader/Loader';
import { useSortableData } from "../../hooks/sort.hook";
import { AuthContext } from "../../context/AuthContext";
import { useCrypto } from "../../hooks/crypto.hook";
import { getClassNamesFor } from '../../common/getClassNamesFor';
import { SetSchedulerModal } from "../../components/SetSchedulerModal/SetSchedulerModal";
import { ActivateTenantModal } from "../../components/ActivateTenantModal/ActivateTenantModal";
import { DeactivateTenantModal } from "../../components/DeactivateTenantModal/DeactivateTenantModal";
import { DeleteTenantModal } from "../../components/DeleteTenantModal/DeleteTenantModal";
import './TenantsList.css';

export const TenantsList = () => {
  const { loading, request, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { token } = useContext(AuthContext);
  const { decryptData } = useCrypto();

  const [tenants, setTenants] = useState([]);
  const [tenantName, setTenantName] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editTenant, setEditTenant] = useState({
    tenantId: '',
    tenantName: '',
    type: '',
    scheduledLaunch: null,
  });

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditTenant({
      tenantId: '',
      tenantName: '',
      type: '',
      scheduledLaunch: null,
    });
  };

  const openActivateModal = () => {
    setActivateModal(true);
  }

  const openDeactivateModal = () => {
    setDeactivateModal(true);
  }

  const openDeleteModal = () => {
    setDeleteModal(true);
  }

  const closeActivateTenantModal = () => {
    setActivateModal(false);
    setEditTenant({
      tenantId: '',
      tenantName: '',
      type: '',
      scheduledLaunch: null,
    });
  };

  const closeDeactivateTenantModal = () => {
    setDeactivateModal(false);
    setEditTenant({
      tenantId: '',
      tenantName: '',
      type: '',
      scheduledLaunch: null,
    });
  };

  const closeDeleteTenantModal = () => {
    setDeleteModal(false);
    setEditTenant({
      tenantId: '',
      tenantName: '',
      type: '',
      scheduledLaunch: null,
    });
  };

  const { items, requestSort, sortConfig } = useSortableData(tenants);

  const getAllTenants = useCallback(async () => {
    try {
      if (token) {
        const activeTenants = await request('/back_office/api/user/all_active_tenants', 'GET', null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const inactiveTenants = await request('/back_office/api/user/all_inactive_tenants', 'GET', null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptRes = [...decryptData(activeTenants), ...decryptData(inactiveTenants)];
        setTenants(decryptRes);

        return tenants;
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    getAllTenants();
  }, [getAllTenants]);

  return (
    <div className="tenants-main">
      { loading || secondaryLoading
        ? <Loader />
        : !!tenants.length
          ? <div>
              <div className="tenants-main-description">
                <h5 className="tenants-main-name">Active Tenants</h5>
                <div className="input-edit-tenants">
                  <input
                    placeholder="Search tenant..."
                    value={tenantName}
                    type='text'
                    onChange={(e) => setTenantName(e.target.value)}
                  />
                  <i
                    role='button'
                    onClick={() => setTenantName('')}
                    className={cn({ 'tenants-clear-disabled': !tenantName })}
                  />
                </div>
              </div>
              <table className="tenants-table">
                <thead className="table-th-tenants">
                  <tr className="not-clickable-tenants tr-grid-tenants tr-tenants">
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('tenantName')}
                        className={getClassNamesFor('tenantName', sortConfig)}
                      >
                        Tenant Name
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('type')}
                        className={getClassNamesFor('type', sortConfig)}
                      >
                        Sync Type
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('scheduledLaunch')}
                        className={getClassNamesFor('scheduledLaunch', sortConfig)}
                      >
                        Launch Date
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('launched')}
                        className={getClassNamesFor('launched', sortConfig)}
                      >
                        Launch Status
                      </button>
                    </th>
                    <th>
                      Set the Launch Date
                    </th>
                    <th>
                    </th>
                    <th>
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body table-body-tenants">
                  {items
                    .filter(t => t.name.toLowerCase().includes(tenantName.toLowerCase()) && t.isActive === true)
                    .map((t) => (
                      <tr
                        key={t.id}
                        className={"tr-grid-tenants clickable-row-tenants tr-tenants"}
                        >
                          <td className={'list-td-tenants'}>{t.name}</td>
                          <td className={`list-td-tenants list-td-tenants-tenant-${t.type}`}>{t.type}</td>
                          <td className='list-td-tenants'>
                            <span className='td-tenants-all-users'>{
                              !!t.scheduledLaunch
                                ? moment(t.scheduledLaunch).format('MM/DD/YYYY')
                                : '-'
                            }</span>
                          </td>
                          <td className='list-td-tenants'>
                            {t.scheduledLaunch
                              ? t.launched
                                ? <div className='tenants-list-td-status-configured'>Launched</div>
                                : <div className='tenants-list-td-status-scheduled'>Scheduled</div>
                              : <div className='tenants-list-td-status-not-defined'>Not defined</div>
                            }
                          </td>
                          <td className='list-td-tenants'>
                            {!t.launched &&
                              <i
                                role='button'
                                onClick={() => {
                                  setEditTenant({
                                    tenantId: t.id,
                                    tenantName: t.name,
                                    type: t.type,
                                    scheduledLaunch: t.scheduledLaunch ? moment(t.scheduledLaunch).format('YYYY-MM-DD') : null,
                                  })
                                  openModal()
                                }}
                              />
                            }
                          </td>
                          <td>
                            {t.type!=='csv' && <button
                              className="all-tenant-button deactivate-tenant"
                              onClick={() => {
                                setEditTenant({
                                  tenantId: t.id,
                                  tenantName: t.name,
                                  type: t.type,
                                  scheduledLaunch: t.scheduledLaunch ? moment(t.scheduledLaunch).format('YYYY-MM-DD') : null,
                                })
                                openDeactivateModal()
                              }}
                            >
                              Deactivate
                            </button>}
                          </td>
                          <td>
                            <button
                              className="all-tenant-button delete-tenant"
                              onClick={() => {
                                setEditTenant({
                                  tenantId: t.id,
                                  tenantName: t.name,
                                  type: t.type,
                                  scheduledLaunch: t.scheduledLaunch ? moment(t.scheduledLaunch).format('YYYY-MM-DD') : null,
                                })
                                openDeleteModal()
                              }}
                            >
                              Delete
                            </button>
                          </td>
                      </tr>
                  ))}
                </tbody>
              </table>

              {tenants.some(tenant => !tenant.isActive && tenant.exists) && <>
                <div className="tenants-main-description">
                  <h5 className="tenants-main-name">Inactive Tenants</h5>
                </div>
                <table className="tenants-table">
                  <thead className="table-th-tenants">
                    <tr className="not-clickable-tenants tr-grid-tenants tr-tenants">
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('tenantName')}
                          className={getClassNamesFor('tenantName', sortConfig)}
                        >
                          Tenant Name
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('type')}
                          className={getClassNamesFor('type', sortConfig)}
                        >
                          Sync Type
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('scheduledLaunch')}
                          className={getClassNamesFor('scheduledLaunch', sortConfig)}
                        >
                          Launch Date
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('launched')}
                          className={getClassNamesFor('launched', sortConfig)}
                        >
                          Launch Status
                        </button>
                      </th>
                      <th>
                      </th>
                      <th>
                      </th>
                      <th>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body table-body-tenants">
                    {items
                      .filter(t => t.name.toLowerCase().includes(tenantName.toLowerCase())
                        && t.isActive === false && t.type !== 'csv')
                      .map((t) => (
                        <tr
                          key={t.id}
                          className={"tr-grid-tenants clickable-row-tenants tr-tenants"}
                          >
                            <td className={'list-td-tenants'}>{t.name}</td>
                            <td className={`list-td-tenants list-td-tenants-tenant-${t.type}`}>{t.type}</td>
                            <td className='list-td-tenants'>
                              <span className='td-tenants-all-users'>{
                                !!t.scheduledLaunch
                                  ? moment(t.scheduledLaunch).format('MM/DD/YYYY')
                                  : '-'
                              }</span>
                            </td>
                            <td className='list-td-tenants'>
                              {t.scheduledLaunch
                                ? t.launched
                                  ? <div className='tenants-list-td-status-configured'>Launched</div>
                                  : <div className='tenants-list-td-status-scheduled'>Scheduled</div>
                                : <div className='tenants-list-td-status-not-defined'>Not defined</div>
                              }
                            </td>
                            <td></td>
                            <td className='list-td-tenants'>
                              <button
                                className="all-tenant-button activate-tenant"
                                onClick={() => {
                                  setEditTenant({
                                    tenantId: t.id,
                                    tenantName: t.name,
                                    type: t.type,
                                    scheduledLaunch: t.scheduledLaunch ? moment(t.scheduledLaunch).format('YYYY-MM-DD') : null,
                                  })
                                  openActivateModal()
                                }}
                              >
                                Activate
                              </button>
                            </td>
                            <td></td>
                        </tr>
                    ))}
                  </tbody>
                </table>
              </>}
            </div>
          : <p className="tenants-description">
              You don't have any created tenants yet.
            </p>
      }

      <SetSchedulerModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        editTenant={editTenant}
        setEditTenant={setEditTenant}
        callback={getAllTenants}
      />

      <ActivateTenantModal
        isOpen={activateModal}
        onRequestClose={closeActivateTenantModal}
        editTenant={editTenant}
        callback={getAllTenants}
        requestWithSecondaryLoading={requestWithSecondaryLoading}
        secondaryLoading={secondaryLoading}
      />

      <DeactivateTenantModal
        isOpen={deactivateModal}
        onRequestClose={closeDeactivateTenantModal}
        editTenant={editTenant}
        callback={getAllTenants}
        requestWithSecondaryLoading={requestWithSecondaryLoading}
        secondaryLoading={secondaryLoading}
      />

      <DeleteTenantModal
        isOpen={deleteModal}
        onRequestClose={closeDeleteTenantModal}
        editTenant={editTenant}
        callback={getAllTenants}
        requestWithSecondaryLoading={requestWithSecondaryLoading}
        secondaryLoading={secondaryLoading}
      />
    </div>
  )
}
